import { AREA_UNIT_TAGS } from "constants/dbEnums";
import { ZONE_TYPE } from "constants/dbEnums";
import { PROPERTY_CONDITION } from "constants/dbEnums";
import { MONTHS } from "constants/dbEnums";
import { BALCONIES_TAGS } from "constants/dbEnums";
import { SECURITY_DEPOSIT_TYPE_TAGS } from "constants/dbEnums";
import { LOCK_IN_PERIOD_TYPE } from "constants/dbEnums";
import { PANTRY } from "constants/dbEnums";
import { OWNERSHIP } from "constants/dbEnums";
import { CONSTRUCTION_STATUS_OF_WALL } from "constants/dbEnums";
import { FIRE_SAFETY_MEASURES } from "constants/dbEnums";
import { DISCOUNT_TYPE } from "constants/dbEnums";
import { USER_ROLE } from "constants/dbEnums";
import { PROPERTY_COLLECTIONS } from "constants/dbEnums";
import { CONSTRUCTION_STATUS_TAGS, COMMERCIAL_PROPERTY_TYPE } from "constants/dbEnums";
import { AD_TYPE_TAGS } from "constants/dbEnums";
import { FACING_TAGS } from "constants/dbEnums";
import { AGE_OF_PROPERTY_TAGS } from "constants/dbEnums";
import { COMMERCIAL_AMENITIES } from "constants/dbEnums";
import { FLOORING_TAGS } from "constants/dbEnums";
import { LOCATION_HUB_TAGS } from "constants/dbEnums";
import { PARKING_TYPE } from "constants/dbEnums";
import { NOTICE_PERIOD } from "constants/dbEnums";
import { LEASE_YEARS } from "constants/dbEnums";
import { BATHROOMS_TAGS } from "constants/dbEnums";
import { POSSESSION_PERIOD_TAGS } from "constants/dbEnums";
import { COMMERCIAL_SUITABLE_FOR } from "constants/dbEnums";

const Joi = require("joi");

const commercialSchema = {
  create: Joi.object({
    owner_id: Joi.string().required(),
    type: Joi.string().required().valid(...Object.values(PROPERTY_COLLECTIONS)),
    owner_role: Joi.string().required().valid(...Object.values(USER_ROLE)),
    description: Joi.string().required().trim(),
    ad_type: Joi.string()
      .valid(...Object.values(AD_TYPE_TAGS))
      .required(),
    construction_status: Joi.string()
      .valid(...Object.values(CONSTRUCTION_STATUS_TAGS))
      .required(),
    area: Joi.object({
      size: Joi.number().positive().min(1).max(9999999).required(),
      unit: Joi.string()
        .valid(...Object.values(AREA_UNIT_TAGS))
        .required(),
    }).required(),
    carpet_area: Joi.object({
      size: Joi.number().positive().allow(0).min(1).max(9999999),
      unit: Joi.string()
        .valid(...Object.values(AREA_UNIT_TAGS), ""),
    }),
    property_type: Joi.string()
      .valid(...Object.values(COMMERCIAL_PROPERTY_TYPE))
      .required(),
    zone_type: Joi.when('property_type', {
      is: 'office',
      then: Joi.string().valid(...Object.values(ZONE_TYPE)).required(),
      otherwise: Joi.string().valid(...Object.values(ZONE_TYPE), ""),
    }),
    suitable_for: Joi.array()
      .items(
        Joi.string()
          .valid(...Object.values(COMMERCIAL_SUITABLE_FOR))
          .trim()
      ),
    property_condition: Joi.when('property_type', {
      is: 'office',
      then: Joi.string().valid(...Object.values(PROPERTY_CONDITION)).required(),
      otherwise: Joi.forbidden(),
    }),
    possession_period: Joi.when("construction_status", {
      is: Joi.valid("under_construction"),
      then: Joi.object({
        period: Joi.string().valid(...Object.values(POSSESSION_PERIOD_TAGS)).required(),
        month: Joi.when("period", {
          is: Joi.string().valid(...Object.values(POSSESSION_PERIOD_TAGS).filter(key => key.startsWith('by_'))),
          then: Joi.string().valid(...Object.values(MONTHS)).required(),
          otherwise: Joi.string().valid("")
        })
      }).required(),
      otherwise: Joi.forbidden()
    }),
    maintenance_charges: Joi.number().positive().allow(0).max(9000000),
    booking_amount: Joi.number().positive().allow(0).max(9000000),
    price_per_sq_unit: Joi.number().positive().allow(0),
    annual_dues_payable: Joi.number().positive().allow(0),
    other_charges: Joi.number().positive().allow(0).max(9000000),
    washrooms: Joi.string()
      .valid(...Object.values(BATHROOMS_TAGS), ""),
    balconies: Joi.string()
      .valid(...Object.values(BALCONIES_TAGS), ""),
    is_on_lease: Joi.when("ad_type", {
      is: Joi.valid("rent"),
      then: Joi.boolean().required(),
      otherwise: Joi.forbidden(),
    }),
    lease_years: Joi.when("ad_type", {
      is: Joi.valid("rent"),
      then: Joi.when("is_on_lease", {
        is: true,
        then: Joi.string()
          .valid(...Object.values(LEASE_YEARS))
          .required(),
        otherwise: Joi.forbidden(),
      }),
      otherwise: Joi.forbidden(),
    }),
    security_deposit: Joi.when("is_on_lease", {
      is: true,
      then: Joi.forbidden(),
      otherwise: Joi.object({
        type: Joi.string().valid(...Object.values(SECURITY_DEPOSIT_TYPE_TAGS), ""),
        amount: Joi.when('type', {
          is: ['fixed', 'multiple_of_rents'],
          then: Joi.number().min(100).required(),
          otherwise: Joi.number().valid(0),
        })
      }),
    }),
    notice_period: Joi.when("is_on_lease", {
      is: true,
      then: Joi.forbidden(),
      otherwise: Joi.number().valid(...Object.values(NOTICE_PERIOD), 0),
    }),
    lock_in_period: Joi.when("is_on_lease", {
      is: true,
      then: Joi.forbidden(),
      otherwise: Joi.object({
        type: Joi.string().valid(...Object.values(LOCK_IN_PERIOD_TYPE), ""),
        period: Joi.when('type', {
          is: 'custom',
          then: Joi.number().integer().max(30).required(),
          otherwise: Joi.number().valid(0)
        })
      }),
    }),
    office_features: Joi.when('property_type', {
      is: 'office',
      then: Joi.object().keys({
        parking_type: Joi.string().valid(...Object.values(PARKING_TYPE)).required(),
        ups: Joi.when('property_condition', {
          is: PROPERTY_CONDITION.BARE_SHELL,
          then: Joi.forbidden(),
          otherwise: Joi.boolean(),
        }),
        central_ac: Joi.boolean(),
        reception_area: Joi.boolean(),
        oxygen_duct: Joi.boolean(),
        furnishing: Joi.when('property_condition', {
          is: PROPERTY_CONDITION.BARE_SHELL,
          then: Joi.forbidden(),
          otherwise: Joi.boolean(),
        }),
        private_parking_basement: Joi.boolean(),
        private_parking_outside: Joi.boolean(),
        public_parking: Joi.boolean(),
        parking: Joi.number().positive(),
        conference_room_available: Joi.when('property_condition', {
          is: PROPERTY_CONDITION.BARE_SHELL,
          then: Joi.forbidden(),
          otherwise: Joi.boolean(),
        }),
        conference_room: Joi.when('property_condition', {
          is: PROPERTY_CONDITION.BARE_SHELL,
          then: Joi.forbidden(),
          otherwise: Joi.string(),
        }),
        bathrooms_available: Joi.boolean(),
        private_washroom: Joi.string(),
        public_washroom: Joi.string(),
        pantry_available: Joi.boolean(),
        pantry: Joi.string().valid(...Object.values(PANTRY)).when('pantry_available', {
          is: true,
          then: Joi.required(),
          otherwise: Joi.forbidden(),
        }),
        pantry_size: Joi.number().min(1).max(9999),
        pantry_size_label: Joi.string().valid(...Object.values(AREA_UNIT_TAGS)),
        passenger_lift: Joi.string().required(),
        service_lift: Joi.string().required(),
      }),
      otherwise: Joi.forbidden()
    }),
    location_hub: Joi.string()
      .valid(...Object.values(LOCATION_HUB_TAGS), ""),
    ownership: Joi.when('property_type', {
      is: 'other',
      then: Joi.forbidden(),
      otherwise: Joi.string().valid(...Object.values(OWNERSHIP)).required()
    }),
    flooring_type: Joi.string()
      .valid(...Object.values(FLOORING_TAGS), ""),
    construction_status_of_wall: Joi.when('property_type', {
      is: 'other',
      then: Joi.forbidden(),
      otherwise: Joi.when('property_condition', {
        is: 'bare_shell',
        then: Joi.string().valid(...Object.values(CONSTRUCTION_STATUS_OF_WALL)).required(),
        otherwise: Joi.forbidden(),
      }),
    }),
    amenities: Joi.array().items(
      Joi.string()
        .valid(...Object.values(COMMERCIAL_AMENITIES))
        .trim()
    ),
    fire_safety_measures: Joi.array().items(
      Joi.string()
        .valid(...Object.values(FIRE_SAFETY_MEASURES))
        .trim()
    ),
    occupancy_certificate: Joi.boolean().default(false),
    age_of_property: Joi.when('construction_status', {
      is: 'ready_to_move',
      then: Joi.string().valid(...Object.values(AGE_OF_PROPERTY_TAGS), "").required(),
      otherwise: Joi.forbidden(),
    }),
    is_hot_deal: Joi.when("ad_type", {
      is: "sell",
      then: Joi.boolean(),
      otherwise: Joi.forbidden()
    }),
    discount: Joi.when("is_hot_deal", {
      is: Joi.valid(true).required(),
      then: Joi.object({
        type: Joi.string().valid(...Object.values(DISCOUNT_TYPE)),
        amount: Joi.when("type", {
          is: Joi.valid(DISCOUNT_TYPE.PERCENTAGE),
          then: Joi.number().min(1).max(90),
          otherwise: Joi.number().min(1000).max(90000000)
        }),
      }).required(),
      otherwise: Joi.forbidden(),
    }),
    discount_validity: Joi.when("is_hot_deal", {
      is: Joi.valid(true).required(),
      then: Joi.date().required(),
      otherwise: Joi.forbidden(),
    }),
    financing_available: Joi.when("is_hot_deal", {
      is: Joi.valid(true).required(),
      then: Joi.boolean().required(),
      otherwise: Joi.forbidden(),
    }),
    approx_price: Joi.number().positive().min(1).max(1000000000).required(),
    available_from: Joi.date().min((new Date().setUTCHours(0, 0, 0, 0))).required(),
    negotiable: Joi.boolean(),
    is_electricity_and_water_charge: Joi.when("ad_type", {
      is: Joi.valid(AD_TYPE_TAGS.RENT),
      then: Joi.boolean(),
      otherwise: Joi.forbidden()
    }),
    office_noc_certified: Joi.boolean(),
    facing: Joi.string().valid(...Object.values(FACING_TAGS), ""),
    photos: Joi.array().items(Joi.string().trim()),
    total_cabins: Joi.when("property_type", {
      is: "office",
      then: Joi.when('property_condition', {
        is: PROPERTY_CONDITION.BARE_SHELL,
        then: Joi.forbidden(),
        otherwise: Joi.number().integer().min(0).max(999).required(),
      }),
      otherwise: Joi.forbidden()
    }),
    total_seats: Joi.when("property_type", {
      is: "office",
      then: Joi.when("property_condition", {
        is: PROPERTY_CONDITION.BARE_SHELL,
        then: Joi.forbidden(),
        otherwise: Joi.number().integer().min(0).max(999999).required(),
      }),
      otherwise: Joi.forbidden()
    }),
    total_meeting_rooms: Joi.when("property_type", {
      is: "office",
      then: Joi.when('property_condition', {
        is: PROPERTY_CONDITION.BARE_SHELL,
        then: Joi.forbidden,
        otherwise: Joi.number().integer().min(0).max(999).required(),
      }),
      otherwise: Joi.forbidden()
    }),
    allow_brokers: Joi.boolean(),
    ups_and_dg_price_included: Joi.boolean(),
    tax_and_govt_charges_excluded: Joi.when("ad_type", {
      is: Joi.valid(AD_TYPE_TAGS.SELL),
      then: Joi.boolean(),
      otherwise: Joi.forbidden()
    }),
    total_floors: Joi.when("property_type", {
      is: "office",
      then: Joi.number().min(0).max(90).integer().required(),
      otherwise: Joi.forbidden(),
    }),
    your_floor: Joi.when("property_type", {
      is: "office",
      then: Joi.number().integer().min(0)
        .max(Joi.ref("total_floors"))
        .message('"your_floor" must be less than or equal to "total_floors"').required(),
      otherwise: Joi.forbidden(),
    }),
    location: Joi.object({
      type: Joi.string(),
      label: Joi.string(),
      place_type: Joi.string().allow(""),
      coordinates: Joi.array().items(Joi.number()).required(),
      city: Joi.string().required().trim(),
      state: Joi.string(),
      state_code: Joi.string().allow(""),
      country_code: Joi.string().allow(""),
      country: Joi.string(),
      locality: Joi.string().required(),
      sub_locality: Joi.string().allow(""),
      street: Joi.string().allow("").trim(),
      building: Joi.string().allow(""),
      postal_code: Joi.string().allow(""),
    }).required()
    .custom((value, helpers) => {
      if (!value.locality || value.locality.trim() === "") {
        return helpers.error("any.custom", { key: "locality" });
      }
      return value;
    }),
    yearly_appreciation: Joi.when("ad_type", {
      is: Joi.valid("rent"),
      then: Joi.when("property_type", {
        is: Joi.valid("office"),
        then: Joi.when("is_on_lease", {
          is: false,
          then: Joi.number().integer().max(99).required(),
          otherwise: Joi.forbidden(),
        }),
        otherwise: Joi.forbidden(),
      }),
      otherwise: Joi.forbidden(),
    }),
  }).unknown(),
  update: Joi.object({
    owner_id: Joi.string().required(),
    type: Joi.string().required().valid(...Object.values(PROPERTY_COLLECTIONS)),
    owner_role: Joi.string().required().valid(...Object.values(USER_ROLE)),
    description: Joi.string().required().trim(),
    ad_type: Joi.string()
      .valid(...Object.values(AD_TYPE_TAGS))
      .required(),
    construction_status: Joi.string()
      .valid(...Object.values(CONSTRUCTION_STATUS_TAGS))
      .required(),
    area: Joi.object({
      size: Joi.number().positive().min(1).max(9999999).required(),
      unit: Joi.string()
        .valid(...Object.values(AREA_UNIT_TAGS))
        .required(),
    }).required(),
    carpet_area: Joi.object({
      size: Joi.number().positive().allow(0).min(1).max(9999999),
      unit: Joi.string()
        .valid(...Object.values(AREA_UNIT_TAGS), ""),
    }),
    property_type: Joi.string()
      .valid(...Object.values(COMMERCIAL_PROPERTY_TYPE))
      .required(),
    zone_type: Joi.when('property_type', {
      is: 'office',
      then: Joi.string().valid(...Object.values(ZONE_TYPE)).required(),
      otherwise: Joi.string().valid(...Object.values(ZONE_TYPE), ""),
    }),
    suitable_for: Joi.array()
      .items(
        Joi.string()
          .valid(...Object.values(COMMERCIAL_SUITABLE_FOR))
          .trim()
      ),
    property_condition: Joi.when('property_type', {
      is: 'office',
      then: Joi.string().valid(...Object.values(PROPERTY_CONDITION)).required(),
      otherwise: Joi.forbidden(),
    }),
    possession_period: Joi.when("construction_status", {
      is: Joi.valid("under_construction"),
      then: Joi.object({
        period: Joi.string().valid(...Object.values(POSSESSION_PERIOD_TAGS)).required(),
        month: Joi.when("period", {
          is: Joi.string().valid(...Object.values(POSSESSION_PERIOD_TAGS).filter(key => key.startsWith('by_'))),
          then: Joi.string().valid(...Object.values(MONTHS)).required(),
          otherwise: Joi.string().valid("")
        })
      }).required(),
      otherwise: Joi.forbidden()
    }),
    maintenance_charges: Joi.number().positive().allow(0).max(9000000),
    booking_amount: Joi.number().positive().allow(0).max(9000000),
    price_per_sq_unit: Joi.number().positive().allow(0),
    annual_dues_payable: Joi.number().positive().allow(0),
    other_charges: Joi.number().positive().allow(0).max(9000000),
    washrooms: Joi.string()
      .valid(...Object.values(BATHROOMS_TAGS), ""),
    balconies: Joi.string()
      .valid(...Object.values(BALCONIES_TAGS), ""),
    is_on_lease: Joi.when("ad_type", {
      is: Joi.valid("rent"),
      then: Joi.boolean().required(),
      otherwise: Joi.forbidden(),
    }),
    lease_years: Joi.when("ad_type", {
      is: Joi.valid("rent"),
      then: Joi.when("is_on_lease", {
        is: true,
        then: Joi.string()
          .valid(...Object.values(LEASE_YEARS))
          .required(),
        otherwise: Joi.forbidden(),
      }),
      otherwise: Joi.forbidden(),
    }),
    security_deposit: Joi.when("is_on_lease", {
      is: true,
      then: Joi.forbidden(),
      otherwise: Joi.object({
        type: Joi.string().valid(...Object.values(SECURITY_DEPOSIT_TYPE_TAGS), ""),
        amount: Joi.when('type', {
          is: ['fixed', 'multiple_of_rents'],
          then: Joi.number().min(100).required(),
          otherwise: Joi.number().valid(0),
        })
      }),
    }),
    notice_period: Joi.when("is_on_lease", {
      is: true,
      then: Joi.forbidden(),
      otherwise: Joi.number().valid(...Object.values(NOTICE_PERIOD), 0),
    }),
    lock_in_period: Joi.when("is_on_lease", {
      is: true,
      then: Joi.forbidden(),
      otherwise: Joi.object({
        type: Joi.string().valid(...Object.values(LOCK_IN_PERIOD_TYPE), ""),
        period: Joi.when('type', {
          is: 'custom',
          then: Joi.number().integer().max(30).required(),
          otherwise: Joi.number().valid(0)
        })
      }),
    }),
    office_features: Joi.when('property_type', {
      is: 'office',
      then: Joi.object().keys({
        parking_type: Joi.string().valid(...Object.values(PARKING_TYPE)).required(),
        ups: Joi.when('property_condition', {
          is: PROPERTY_CONDITION.BARE_SHELL,
          then: Joi.forbidden(),
          otherwise: Joi.boolean(),
        }),
        central_ac: Joi.boolean(),
        reception_area: Joi.boolean(),
        oxygen_duct: Joi.boolean(),
        furnishing: Joi.when('property_condition', {
          is: PROPERTY_CONDITION.BARE_SHELL,
          then: Joi.forbidden(),
          otherwise: Joi.boolean(),
        }),
        private_parking_basement: Joi.boolean(),
        private_parking_outside: Joi.boolean(),
        public_parking: Joi.boolean(),
        parking: Joi.number().positive(),
        conference_room_available: Joi.when('property_condition', {
          is: PROPERTY_CONDITION.BARE_SHELL,
          then: Joi.forbidden(),
          otherwise: Joi.boolean(),
        }),
        conference_room: Joi.when('property_condition', {
          is: PROPERTY_CONDITION.BARE_SHELL,
          then: Joi.forbidden(),
          otherwise: Joi.string(),
        }),
        bathrooms_available: Joi.boolean(),
        private_washroom: Joi.string(),
        public_washroom: Joi.string(),
        pantry_available: Joi.boolean(),
        pantry: Joi.string().valid(...Object.values(PANTRY)).when('pantry_available', {
          is: true,
          then: Joi.required(),
          otherwise: Joi.forbidden(),
        }),
        pantry_size: Joi.number().min(1).max(9999),
        pantry_size_label: Joi.string().valid(...Object.values(AREA_UNIT_TAGS)),
        passenger_lift: Joi.string().required(),
        service_lift: Joi.string().required(),
      }),
      otherwise: Joi.forbidden()
    }),
    location_hub: Joi.string()
      .valid(...Object.values(LOCATION_HUB_TAGS), ""),
    ownership: Joi.when('property_type', {
      is: 'other',
      then: Joi.forbidden(),
      otherwise: Joi.string().valid(...Object.values(OWNERSHIP)).required()
    }),
    flooring_type: Joi.string()
      .valid(...Object.values(FLOORING_TAGS), ""),
    construction_status_of_wall: Joi.when('property_type', {
      is: 'other',
      then: Joi.forbidden(),
      otherwise: Joi.when('property_condition', {
        is: 'bare_shell',
        then: Joi.string().valid(...Object.values(CONSTRUCTION_STATUS_OF_WALL)).required(),
        otherwise: Joi.forbidden(),
      }),
    }),
    amenities: Joi.array().items(
      Joi.string()
        .valid(...Object.values(COMMERCIAL_AMENITIES))
        .trim()
    ),
    fire_safety_measures: Joi.array().items(
      Joi.string()
        .valid(...Object.values(FIRE_SAFETY_MEASURES))
        .trim()
    ),
    occupancy_certificate: Joi.boolean().default(false),
    age_of_property: Joi.when('construction_status', {
      is: 'ready_to_move',
      then: Joi.string().valid(...Object.values(AGE_OF_PROPERTY_TAGS), "").required(),
      otherwise: Joi.forbidden(),
    }),
    is_hot_deal: Joi.when("ad_type", {
      is: "sell",
      then: Joi.boolean(),
      otherwise: Joi.forbidden()
    }),
    discount: Joi.when("is_hot_deal", {
      is: Joi.valid(true).required(),
      then: Joi.object({
        type: Joi.string().valid(...Object.values(DISCOUNT_TYPE)),
        amount: Joi.when("type", {
          is: Joi.valid(DISCOUNT_TYPE.PERCENTAGE),
          then: Joi.number().min(1).max(90),
          otherwise: Joi.number().min(1000).max(90000000)
        }),
      }).required(),
      otherwise: Joi.forbidden(),
    }),
    discount_validity: Joi.when("is_hot_deal", {
      is: Joi.valid(true).required(),
      then: Joi.date().required(),
      otherwise: Joi.forbidden(),
    }),
    financing_available: Joi.when("is_hot_deal", {
      is: Joi.valid(true).required(),
      then: Joi.boolean().required(),
      otherwise: Joi.forbidden(),
    }),
    approx_price: Joi.number().positive().min(1).max(1000000000).required(),
    available_from: Joi.date().required(),
    negotiable: Joi.boolean(),
    is_electricity_and_water_charge: Joi.when("ad_type", {
      is: Joi.valid(AD_TYPE_TAGS.RENT),
      then: Joi.boolean(),
      otherwise: Joi.forbidden()
    }),
    office_noc_certified: Joi.boolean(),
    facing: Joi.string().valid(...Object.values(FACING_TAGS), ""),
    photos: Joi.array().items(Joi.string().trim()),
    total_cabins: Joi.when("property_type", {
      is: "office",
      then: Joi.when('property_condition', {
        is: PROPERTY_CONDITION.BARE_SHELL,
        then: Joi.forbidden(),
        otherwise: Joi.number().integer().min(0).max(999).required(),
      }),
      otherwise: Joi.forbidden()
    }),
    total_seats: Joi.when("property_type", {
      is: "office",
      then: Joi.when("property_condition", {
        is: PROPERTY_CONDITION.BARE_SHELL,
        then: Joi.forbidden(),
        otherwise: Joi.number().integer().min(0).max(999999).required(),
      }),
      otherwise: Joi.forbidden()
    }),
    total_meeting_rooms: Joi.when("property_type", {
      is: "office",
      then: Joi.when('property_condition', {
        is: PROPERTY_CONDITION.BARE_SHELL,
        then: Joi.forbidden,
        otherwise: Joi.number().integer().min(0).max(999).required(),
      }),
      otherwise: Joi.forbidden()
    }),
    allow_brokers: Joi.boolean(),
    ups_and_dg_price_included: Joi.boolean(),
    tax_and_govt_charges_excluded: Joi.when("ad_type", {
      is: Joi.valid(AD_TYPE_TAGS.SELL),
      then: Joi.boolean(),
      otherwise: Joi.forbidden()
    }),
    total_floors: Joi.when("property_type", {
      is: "office",
      then: Joi.number().min(0).max(90).integer().required(),
      otherwise: Joi.forbidden(),
    }),
    your_floor: Joi.when("property_type", {
      is: "office",
      then: Joi.number().integer().min(0)
        .max(Joi.ref("total_floors"))
        .message('"your_floor" must be less than or equal to "total_floors"').required(),
      otherwise: Joi.forbidden(),
    }),
    location: Joi.object({
      type: Joi.string(),
      label: Joi.string(),
      place_type: Joi.string(),
      coordinates: Joi.array().items(Joi.number()).required(),
      city: Joi.string().required().trim(),
      state: Joi.string(),
      state_code: Joi.string(),
      country_code: Joi.string(),
      country: Joi.string(),
      locality: Joi.string().required(),
      sub_locality: Joi.string(),
      street: Joi.string().allow("").trim(),
      building: Joi.string(),
      postal_code: Joi.string(),
    }).required()
    .custom((value, helpers) => {
      if (!value.locality || value.locality.trim() === "") {
        return helpers.error("any.custom", { key: "locality" });
      }
      return value;
    }),
    yearly_appreciation: Joi.when("ad_type", {
      is: Joi.valid("rent"),
      then: Joi.when("property_type", {
        is: Joi.valid("office"),
        then: Joi.when("is_on_lease", {
          is: false,
          then: Joi.number().integer().max(99).required(),
          otherwise: Joi.forbidden(),
        }),
        otherwise: Joi.forbidden(),
      }),
      otherwise: Joi.forbidden(),
    }),
  }).unknown()
}

export default commercialSchema;

export const commercialKeysArray = [
  "ad_type", 
  "construction_status", 
  "age_of_property", 
  "possession_period", 
  "month",
  "property_condition",
  "ownership", 
  "zone_type",
  "available_from",
  "construction_status_of_wall",
  "facing",
  "flooring_type",
  "pantry_available",
  "pantry",
  "total_cabins",
  "total_seats",
  "pantry_size",
  "pantry_size_label",
  "passenger_lift",
  "service_lift",
  "parking_type",
  "private_parking_basement",
  "private_parking_outside",
  "public_parking",
  "is_on_lease",
  "approx_price",
  "booking_amount",
  "annual_dues_payable",
  "maintenance_charges",
  "yearly_appreciation",
  "discount_validity",
  "financing_available",
  "location",
  "locality"
];

export const commercialKeys = {
  "ad_type" : "ad_type_and_construction_status",
  "construction_status" : "ad_type_and_construction_status",
  "age_of_property" : "age_of_property_and_possession_period_and_month",
  "possession_period" : "age_of_property_and_possession_period_and_month",
  "month" : "age_of_property_and_possession_period_and_month",
  "ownership" : "ownership_and_zone_type",
  "zone_type" : "ownership_and_zone_type",
  "available_from" : "available_from_and_construction_status_of_wall",
  "construction_status_of_wall" : "available_from_and_construction_status_of_wall",
  "facing" : "facing_and_flooring_type",
  "flooring_type" : "facing_and_flooring_type",
  "pantry_available" : "pantry_available_and_pantry",
  "pantry" : "pantry_available_and_pantry",
  "total_cabins" : "total_cabins_and_total_seats",
  "total_seats" : "total_cabins_and_total_seats",
  "pantry_size" : "pantry_size_and_pantry_size_label",
  "pantry_size_label" : "pantry_size_and_pantry_size_label",
  "passenger_lift" : "passenger_lift_and_service_lift",
  "service_lift" : "passenger_lift_and_service_lift",
  "parking_type" : "parking_type_and_private_parking_basement",
  "private_parking_basement" : "parking_type_and_private_parking_basement",
  "private_parking_outside" : "private_parking_outside_and_public_parking",
  "public_parking" : "private_parking_outside_and_public_parking",
  "is_on_lease" : "is_on_lease_and_approx_price",
  "approx_price" : "is_on_lease_and_approx_price",
  "booking_amount" : "booking_amount_and_annual_dues_payable",
  "annual_dues_payable"  : "booking_amount_and_annual_dues_payable",
  "maintenance_charges" : "maintenance_charges_and_yearly_appreciation",
  "yearly_appreciation" : "maintenance_charges_and_yearly_appreciation",
  "discount_validity" : "discount_validity_and_financing_available",
  "financing_available" : "discount_validity_and_financing_available",
  "location" : "location_locality",
  "locality" : "location_locality",
  "property_condition":"property_condition",
};
