import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  HStack,
  useColorModeValue,
  InputGroup,
  Input,
  InputRightElement,
  FormLabel,
} from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import { formatDateToDDMONTHNAMEYYYY } from "utils/common";
import ErrorMessage from "./ErrorMessage";

const CalendarInput = ({ value, onChange, name, label,isRequired, error, disablePastDate=true }) => {
  const [date, setDate] = useState(value);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPreset, setSelectedPreset] = useState(null);
  const today = new Date();

  useEffect(() => {
    if (selectedPreset) {
      setDate(selectedPreset);
    }
  }, [selectedPreset]);

  const onDateChange = (value) => {
    if (value <= today && disablePastDate) {
      value = today;
    }
    setSelectedPreset(null);
    setDate(value);
  };

  const applyFilter = () => {
    setIsOpen(false);
    const customEvent = {
      target: {
        name,
        value: date.toISOString()
      }
    }
    onChange(customEvent);
  };

  const calendarBg = useColorModeValue("white", "gray.700");
  const calendarBorderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Box>
      <FormLabel htmlFor={name}>{label}{isRequired && <Box as="span" color="red.500" ml={1}>*</Box>}</FormLabel>
      <InputGroup>
        <Input
          placeholder="Select Date"
          value={value ? formatDateToDDMONTHNAMEYYYY(value) : ""}
          readOnly
          onClick={() => setIsOpen(true)}
          id={name}
        />
        <InputRightElement pointerEvents="none">
          <CalendarIcon color="gray.500" />
        </InputRightElement>
      </InputGroup>
      <ErrorMessage error={error}/>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{label}</ModalHeader>
          <ModalCloseButton />
          <ModalBody style={{ display: "flex", gap: "10px" }}>
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Box
                border="2px solid black"
                as={Calendar}
                onChange={onDateChange}
                value={date}
                className="react-calendar"
                bg={calendarBg}
                borderRadius="md"
                borderColor={calendarBorderColor}
                borderWidth="1px"
                boxShadow="md"
                sx={{
                  ".react-calendar__tile": {
                    padding: "5px",
                    bg: calendarBg,
                    borderRadius: "md",
                    border: "1px solid",
                    borderColor: calendarBorderColor,
                    _hover: { bg: "blue.50" },
                  },
                  ".react-calendar__tile--active": {
                    bg: "blue.500",
                    color: "white",
                    _hover: { bg: "blue.600" },
                  },
                  ".react-calendar__navigation button": {
                    color: "blue.500",
                    _hover: { bg: "blue.50" },
                    minWidth: "30px",
                    height: "30px",
                  },
                  ".react-calendar__navigation button:disabled": {
                    color: "gray.400",
                  },
                }}
              />
              <HStack spacing={3} mt={2} justifyContent="center">
                <Box textAlign="center">
                  <Text fontSize="sm" fontWeight="bold">
                    Selected Date:
                  </Text>
                  <Text fontSize="sm">
                    {date ? formatDateToDDMONTHNAMEYYYY(date) : "N/A"}
                  </Text>
                </Box>
              </HStack>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={applyFilter}
              bg="blue.500"
              color="white"
              _hover={{ bg: "blue.600" }}
              size="sm"
            >
              Apply 
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CalendarInput;
