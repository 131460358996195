import { Box, Button } from '@chakra-ui/react';
import LineChart from 'components/charts/LineAreaChart';
import React, { useEffect, useState } from 'react';
import { LEADS_DATA_FOR_CHART } from './variables/dataForCharts';
import FilterCalendar from 'components/calendar/FilterCalendar';

const DataCharts = () => {
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    return date;
  });
  const [endDate, setEndDate] = useState(() => new Date());
  const leadsData = LEADS_DATA_FOR_CHART;

  const handleCustomDateChange = (days) => {
    const date = new Date();
    date.setDate(date.getDate() - days);
    setStartDate(date);
    setEndDate(new Date());
  };

  const initialChartOptions = {
    chart: {
      id: 'line-chart',
      background: 'white'
    },
    stroke: {
      curve: 'smooth'
    },
    markers: {
      size: 4
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return val;
        }
      }
    },
    xaxis: {
      type: 'datetime', // Ensure the x-axis is of type category
    }
  };

  const [chartData, setChartData] = useState([]);
  const [filteredDates, setFilteredDates] = useState([]);

  const filterDataByDateRange = (data, startDate, endDate) => {
    if (!startDate || !endDate) return data;
    const filteredData = data.filter(item => {
      const itemDate = new Date(item.date);
      return itemDate >= startDate && itemDate <= endDate;
    });
    return filteredData;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const updateChartData = () => {
    const allMonths = Object.keys(leadsData);
    let leadsDataFiltered = [];

    allMonths.forEach(month => {
      const filteredDataForMonth = filterDataByDateRange(leadsData[month]?.days || [], startDate, endDate);
      leadsDataFiltered = leadsDataFiltered.concat(filteredDataForMonth);
    });

    // Sort the data by date to ensure the correct order
    leadsDataFiltered.sort((a, b) => new Date(a.date) - new Date(b.date));

    setChartData([
      {
        name: 'Leads Added',
        data: leadsDataFiltered.map(day => day.leads), // Extract `value`
      },
      {
        name: 'Users Added',
        data: leadsDataFiltered.map(day => day.users), // Extract `value`
      },
      {
        name: 'Property Added',
        data: leadsDataFiltered.map(day => day.property), // Extract `value`
      }
    ]);

    setFilteredDates(leadsDataFiltered.map(day => formatDate(day.date)));
  };

  useEffect(() => {
    updateChartData();
  }, [startDate, endDate]);

  const chartOptions = {
    ...initialChartOptions,
    xaxis: {
      categories: filteredDates, // Use the filtered dates dynamically
      tickAmount: filteredDates.length > 10 ? 10 : filteredDates.length, // Limit to 10 ticks
    },
    title: {
      text: `Leads Line Chart`,
      align: 'left'
    }
  };

  return (
    <Box pt="20px" overflowX="hidden" w="90%"  h="100%">
      <Box
        minH="80vh"
        minW="75vw"
        my="20px"
        rounded="20px"
        p="20px"
        border="1px solid #ccc"
        borderRadius="20px"
        backgroundColor="white"
        position="relative"
        mx="20px"
      >
       
         <Box display="flex" gap="10px" mt="10px" mb="10px" flexWrap="wrap">
         <FilterCalendar
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          onChange={(e) => {
            const { name, value } = e.target;
            if (name === "dateRange") {
              setStartDate(value.startDate);
              setEndDate(value.endDate);
            }
          }}
        />
        <Button  borderRadius="5px"
            borderColor="#637381"
            background={"linear-gradient(180deg, #22ACEE 0%, #0177B4 100%)"}
            color="#ffffff"
            fontSize="sm"
            colorScheme="teal" onClick={() => handleCustomDateChange(7)}>Last 07 Days</Button>
          <Button  borderRadius="5px"
            borderColor="#637381"
            background={"linear-gradient(180deg, #22ACEE 0%, #0177B4 100%)"}
            color="#ffffff"
            fontSize="sm"
            colorScheme="teal" onClick={() => handleCustomDateChange(30)}>Last 30 Days</Button>
          <Button  borderRadius="5px"
            borderColor="#637381"
            background={"linear-gradient(180deg, #22ACEE 0%, #0177B4 100%)"}
            color="#ffffff"
            fontSize="sm"
            colorScheme="teal" onClick={() => handleCustomDateChange(60)}>Last 60 Days</Button>
          <Button  borderRadius="5px"
            borderColor="#637381"
            background={"linear-gradient(180deg, #22ACEE 0%, #0177B4 100%)"}
            color="#ffffff"
            fontSize="sm"
            colorScheme="teal" onClick={() => handleCustomDateChange(90)}>Last 90 Days</Button>
        </Box>
        <LineChart key={JSON.stringify(chartData)} chartData={chartData} chartOptions={chartOptions} />
      </Box>
    </Box>
  );
};

export default DataCharts;
