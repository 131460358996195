import {
  AD_TYPE,
  CONSTRUCTION_STATUS,
  LOCATION_HUB,
  POSSESSION_PERIOD,
} from "constants/dbEnums";
import SelectInput from "../SelectInput";
import { AGE_OF_PROPERTY } from "constants/dbEnums";
import CheckboxComponent from "../Checkbox";
import { Box, Flex } from "@chakra-ui/react";
import { CONSTRUCTION_STATUS_TAGS } from "constants/dbEnums";
import { MONTHS_TAGS } from "constants/dbEnums";
import { OWNERSHIP_TAGS } from "constants/dbEnums";
import { ZONE_TYPE_TAGS } from "constants/dbEnums";
import { PROPERTY_CONDITION_TAGS } from "constants/dbEnums";
import CalendarInput from "../CalendarInput";
import { CONSTRUCTION_STATUS_OF_WALL_TAGS } from "constants/dbEnums";
import { PROPERTY_CONDITION } from "constants/dbEnums";
import { FACING } from "constants/dbEnums";
import { FLOORING } from "constants/dbEnums";
import ValidatedInput from "../ValidatedInput";
import { AREA_UNIT } from "constants/dbEnums";
import MultiSelect from "../MultiSelect";
import { FIRE_SAFETY_MEASURES_TAGS } from "constants/dbEnums";
import { occupancy_certificate } from "constants/dbEnums";
import { office_noc_certified } from "constants/dbEnums";
import { ALLOW_BROKERS } from "constants/dbEnums";
import { PARKING_TYPE_TAGS } from "constants/dbEnums";
import { bathrooms_available } from "constants/dbEnums";
import { BATHROOMS } from "constants/dbEnums";
import { reception_area } from "constants/dbEnums";
import { central_ac } from "constants/dbEnums";
import { oxygen_duct } from "constants/dbEnums";
import { LEASE_YEARS } from "constants/dbEnums";
import { private_parking_basement } from "constants/dbEnums";
import { private_parking_outside } from "constants/dbEnums";
import { public_parking } from "constants/dbEnums";
import { RENT_TYPE } from "constants/dbEnums";
import { is_electricity_and_water_charge } from "constants/dbEnums";
import { is_price_negotiable } from "constants/dbEnums";
import { SECURITY_DEPOSIT_TYPE } from "constants/dbEnums";
import { NOTICE_PERIOD } from "constants/dbEnums";
import { LOCK_IN_PERIOD } from "constants/dbEnums";
import AutoSuggestComponent from "../location/locality";
import ErrorMessage from "../ErrorMessage";
import Image from "../imageUpload/index";
import { COMMERCIAL_AMENITIES_TAGS } from "constants/dbEnums";
import DescriptionGenerator from "../autoGenerateDescription";
import { PANTRY_TAGS } from "constants/dbEnums";
import { PANTRY_AVAILABLE } from "constants/dbEnums";
import { conference_room_available } from "constants/dbEnums";
import { furnishing } from "constants/dbEnums";
import { ups } from "constants/dbEnums";
import { AD_TYPE_TAGS } from "constants/dbEnums";
import { tax_and_govt_charges_excluded } from "constants/dbEnums";
import { ups_and_dg_price_included } from "constants/dbEnums";
import { IS_HOT_DEAL } from "constants/dbEnums";
import { DISCOUNT_TYPE } from "constants/dbEnums";
import { DISCOUNT_TYPE_TAGS } from "constants/dbEnums";
import { FINANCING_AVAILABLE } from "constants/dbEnums";
import { PROPERTY_TYPE_TAGS } from "constants/dbEnums";
import { COMMERCIAL_SUITABLE_FOR } from "constants/dbEnums";
import { getDataFromLocalStorage } from "utils/common";
import CitySelectInput from "../location/citySelectInput";
import { LOCATION_TYPE } from "constants/dbEnums";

const commercialOfficeForm = {
  ad_type_and_construction_status: (props) => {
    const { error, onChange, formData } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      const customEvent = {
        target: {
          name,
          value,
        },
      };
      onChange(customEvent);
    };
    return (
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={formData.ad_type === "sell" ? 2 : 0}>
          <SelectInput
            name="ad_type"
            label="Ad type"
            options={AD_TYPE}
            isRequired={true}
            error={error?.ad_type}
            onChange={(e) => handleChange(e)}
            selectedValue={formData?.ad_type}
            disabled={formData?._id}
          />
        </Box>
        <Box flex="2" ml={2}>
          <CheckboxComponent
            options={CONSTRUCTION_STATUS}
            name="construction_status"
            selectedValues={formData?.construction_status}
            onChange={(e) => handleChange(e)}
            label="Construction Status"
            textSize="lg"
            checkboxSize="lg"
            error={error?.construction_status}
            isRequired={true}
          />
        </Box>
      </Flex>
    );
  },
  age_of_property_and_possession_period_and_month: (props) => {
    const { error, onChange, formData } = props;
    const isUnderCunstruction =
      formData?.construction_status ===
      CONSTRUCTION_STATUS_TAGS.UNDER_CONSTRUCTION;
    const showMonth = formData?.possession_period?.period?.startsWith("by_");
    const handleChange = (e, selectedName) => {
      const { name, value } = e.target;
      const updatedValue = {
        ...formData.possession_period,
        [selectedName]: value,
      };
      const customEvent = {
        target: {
          name,
          value: name === "age_of_property" ? value : updatedValue,
        },
      };
      onChange(customEvent);
    };
    return (
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={showMonth ? 2 : 0}>
          <SelectInput
            name={isUnderCunstruction ? "possession_period" : "age_of_property"}
            label={
              isUnderCunstruction
                ? "Expected Time pf Possession"
                : "Age of Property"
            }
            options={isUnderCunstruction ? POSSESSION_PERIOD : AGE_OF_PROPERTY}
            isRequired={true}
            onChange={(e) => handleChange(e, "period")}
            error={isUnderCunstruction ? error?.possession_period : error?.age_of_property}
            selectedValue={isUnderCunstruction ? formData?.possession_period?.period : formData?.age_of_property}
          />
        </Box>
        {showMonth && (
          <Box flex="1" ml={2}>
            <SelectInput
              name="possession_period"
              label="Select Month"
              options={MONTHS_TAGS}
              isRequired={true}
              onChange={(e) => handleChange(e, "month")}
              error={error?.month}
              selectedValue={formData?.possession_period?.month}
            />
          </Box>
        )}
      </Flex>
    );
  },
  location_hub: (props) => (
    <SelectInput
      name="location_hub"
      label="Location Hub"
      options={LOCATION_HUB}
      selectedValue={props?.formData?.location_hub}
      error={props.error}
      {...props}
    />
  ),
  ownership_and_zone_type: (props) => {
    const { onChange, formData, error } = props;
    const allowZoneType = formData?.property_type === PROPERTY_TYPE_TAGS.office || formData?.property_type === PROPERTY_TYPE_TAGS.warehouse || formData?.property_type === PROPERTY_TYPE_TAGS.commercial_other; 
    const isOthers = formData?.property_type === PROPERTY_TYPE_TAGS.commercial_other;
    const handleChange = (e) => {
      const { name: selectedName, value } = e.target;
      onChange({
        target: {
          name: selectedName,
          value,
        },
      });
    };
    return (
      <Flex justify="space-between" mb={4}>
        {allowZoneType && <Box flex="1" mr={2}>
          <SelectInput
            name="zone_type"
            label="Zone Type"
            options={ZONE_TYPE_TAGS}
            selectedValue={formData.zone_type}
            onChange={(e) => handleChange(e)}
            error={error?.zone_type}
            isRequired={formData?.property_type === PROPERTY_TYPE_TAGS.office && true}
          />
        </Box>}
        {!isOthers && 
        <Box flex="1" ml={2}>
          <SelectInput
            name="ownership"
            label="Ownership"
            options={OWNERSHIP_TAGS}
            onChange={(e) => handleChange(e)}
            isRequired={true}
            error={error?.ownership}
            selectedValue={formData?.ownership}
          />
        </Box>}
      </Flex>
    );
  },
  property_condition_and_washrooms: (props) => {
    const { formData, onChange } = props;
    const isOffice = formData?.property_type === PROPERTY_TYPE_TAGS.office;
    const isOthers = formData?.property_type === PROPERTY_TYPE_TAGS.commercial_other;
    const handleChange = (e, selectedName) => {
      const { value } = e.target;
      const customEvent = {
        target: {
          name: selectedName,
          value,
        },
      };
      onChange(customEvent);
    };
    return (
      <>
        {isOffice ? (
          <CheckboxComponent
            options={PROPERTY_CONDITION_TAGS}
            name="property_condition"
            selectedValues={props?.formData?.property_condition}
            onChange={(e)=>handleChange(e, "property_condition")}
            label="Property Condition"
            textSize="lg"
            checkboxSize="lg"
            error={props?.error}
            isRequired={true}
          />
        ) : (
          <>
          {
            (!isOthers) &&
          <SelectInput
            name="washrooms"
            label="No. of washrooms"
            options={BATHROOMS}
            selectedValue={props.formData.washrooms}
            {...props}
            onChange={(e)=>handleChange(e, "washrooms")}
          />
        }
        </>
        )}
      </>
    );
  },
  available_from_and_construction_status_of_wall: (props) => {
    const { onChange, formData, error } = props;
    const showConstructionStatus =
      formData?.property_condition === PROPERTY_CONDITION.BARE_SHELL;
    const handlChange = (e) => {
      const { name, value } = e.target;
      onChange({
        target: {
          name: name,
          value: value,
        },
      });
    };
    return (
      <>
        <Flex justify="space-between" mb={4}>
          <Box flex="2" mr={showConstructionStatus ? 2 : 0}>
            <CalendarInput
              name="available_from"
              label="Available From"
              value={formData?.available_from}
              isRequired={true}
              onChange={(e) => handlChange(e)}
              error={error?.available_from}
            />
          </Box>
          {showConstructionStatus && (
            <Box flex="2" ml={2}>
              <SelectInput
                name="construction_status_of_wall"
                label="Construction status of Wall"
                options={CONSTRUCTION_STATUS_OF_WALL_TAGS}
                onChange={(e) => handlChange(e)}
                isRequired={true}
                error={error?.construction_status_of_wall}
                selectedValue={formData?.construction_status_of_wall}
              />
            </Box>
          )}
        </Flex>
      </>
    );
  },
  facing_and_flooring_type: (props) => {
    const { error, onChange, formData } = props;
    const isOthers = formData?.property_type === PROPERTY_TYPE_TAGS.commercial_other;
    const handleChange = (e) => {
      const { name, value } = e.target;
      const customEvent = {
        target: {
          name,
          value,
        },
      };
      onChange(customEvent);
    };
    return (
      <>
      { !isOthers &&
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={2}>
          <SelectInput
            name="facing"
            label="Facing"
            options={FACING}
            onChange={(e) => handleChange(e)}
            error={error?.facing}
            selectedValue={formData?.facing}
          />
        </Box>
        <Box flex="1" ml={2}>
          <SelectInput
            name="flooring_type"
            label="Flooring Type"
            options={FLOORING}
            onChange={(e) => handleChange(e)}
            error={error?.flooring_type}
            selectedValue={formData?.flooring_type}
          />
        </Box>
      </Flex>
    }
    </>
    );
  },
  area: (props) => {
    const { onChange, formData, name: selectedName, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      const updatedValue = {
        ...formData.area,
        [name]: value,
      };
      onChange({
        target: {
          name: selectedName,
          value: updatedValue,
        },
      });
    };
    return (
      <>
      <Flex>
        <div style={{ flex: 3 }}>
          <ValidatedInput
            name="size"
            placeholder="Enter Build up area"
            type="number"
            onChange={(e) => handleChange(e)}
            label="Build Up Area"
            isRequired={true}
            valueInNumber={true}
            error={error?.size}
            value={formData?.area?.size}
          />
        </div>
        <div style={{ flex: 2 }}>
          <SelectInput
            name="unit"
            label="Unit"
            options={AREA_UNIT}
            selectedValue={formData?.area?.unit}
            onChange={(e) => handleChange(e)}
            error={error?.unit}
            isRequired={true}
          />
        </div>
      </Flex>
      {typeof error === "string" && <ErrorMessage error={error} />}
      </>
    );
  },
  carpet_area: (props) => {
    const { onChange, formData, name: selectedName, value, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      const updatedValue = {
        ...formData.carpet_area,
        [name]: value,
      };
      onChange({
        target: {
          name: selectedName,
          value: updatedValue,
        },
      });
    };
    return (
      <>
      <Flex>
        <div style={{ flex: 3 }}>
          <ValidatedInput
            name="size"
            placeholder="Enter carpet area"
            type="number"
            label="Carpet Area"
            valueInNumber={true}
            onChange={(e) => handleChange(e)}
            error={error?.size}
            value={formData?.carpet_area?.size}
          />
        </div>
        <div style={{ flex: 2 }}>
          <SelectInput
            name="unit"
            label="Unit"
            options={AREA_UNIT}
            selectedValue={value.unit}
            onChange={(e) => handleChange(e)}
            error={error?.unit}
          />
        </div>
      </Flex>
      {typeof error === "string" && <ErrorMessage error={error} />}
      </>
    );
  },
  fire_safety_measures: (props) => {
    const {formData} = props;
    const isOthers = formData?.property_type === PROPERTY_TYPE_TAGS.commercial_other;
    return (
      <>
      {
        !isOthers &&
        <MultiSelect
      name="fire_safety_measures"
      label="Fire Safety Measures"
      options={FIRE_SAFETY_MEASURES_TAGS}
      displayValue="name"
      value={props.value}
      onChange={props.onChange}
      error={props.error}
    />
      }
      </>
    )
  },
  total_floors: (props) => {
    const {formData} = props;
    const isOffice = formData?.property_type === PROPERTY_TYPE_TAGS.office;
    return (
      <>
      { isOffice &&
        <ValidatedInput
        name="total_floors"
        label="Total Floors"
        value={props.value}
        placeholder="Enter no. of Floors"
        type="number"
        isRequired={true}
        {...props}
        valueInNumber={true}
        error={props.error}
      />
      }
      </>
    )
  },
  your_floor: (props) => {
    const {formData} = props;
    const isOffice = formData?.property_type === PROPERTY_TYPE_TAGS.office;
    return (
      <>
      { isOffice &&
        <ValidatedInput
        name="your_floor"
        label="Your Floor"
        value={props.value}
        placeholder="Enter no. of your floor"
        type="number"
        isRequired={true}
        valueInNumber={true}
        onChange={props?.onChange}
        error={props.error}
        isDisabled={props.formData?.total_floors <= 0}
      />
      }
      </>
    )
  },
  occupancy_certificate_and_office_noc_certified: (props) => {
    const { onChange, error, formData } = props;
    const isOffice = formData?.property_type === PROPERTY_TYPE_TAGS.office;
    const showSuitableFor = formData?.property_type !== PROPERTY_TYPE_TAGS.warehouse && formData?.property_type !== PROPERTY_TYPE_TAGS.commercial_other; 
    const toPascalCase = (str) => {
      return str
        .replace(/_/g, ' ')
        .toLowerCase()
        .replace(/\b\w/g, char => char.toUpperCase());
    };
    const commercialSuitableForArray = Object.entries(COMMERCIAL_SUITABLE_FOR).map(([key, value]) => ({
      id: value,
      name: toPascalCase(key),
    }));
    const handlChange = (e) => {
      const { name, checked } = e.target;
      onChange({
        target: {
          name,
          value: checked,
        },
      });
    };
    return (
      <>
      {
        isOffice ?
        <Flex gap={4}>
        <Box flex={2}>
          <CheckboxComponent
            options={occupancy_certificate}
            name="occupancy_certificate"
            onChange={(e) => handlChange(e)}
            textSize="lg"
            checkboxSize="lg"
            error={error?.occupancy_certificate}
            {...(formData?.occupancy_certificate && {selectedValues: "true"})}
          />
        </Box>
        <Box flex={2}>
          <CheckboxComponent
            options={office_noc_certified}
            name="office_noc_certified"
            onChange={(e) => handlChange(e)}
            textSize="lg"
            checkboxSize="lg"
            error={error?.office_noc_certified}
            {...(formData?.office_noc_certified && {selectedValues: "true"})}
          />
        </Box>
      </Flex> : 
      showSuitableFor && <MultiSelect
      name="suitable_for"
      label="Suitable For"
      options={commercialSuitableForArray}
      displayValue="name"
      value={formData?.suitable_for}
      onChange={props.onChange}
      error={props.error}
    />
      }
      </>
    );
  },
  allow_brokers: (props) => {
    const { onChange, formData } = props;
    const handlChange = (e) => {
      const { name, checked } = e.target;
      onChange({
        target: {
          name,
          value: checked,
        },
      });
    };
    return (
      <Box>
        <CheckboxComponent
          options={ALLOW_BROKERS}
          name="allow_brokers"
          onChange={(e) => handlChange(e)}
          textSize="lg"
          checkboxSize="lg"
          {...(formData?.allow_brokers && {selectedValues: "true"})}
        />
      </Box>
    );
  },
  total_cabins_and_total_seats: (props) => {
    const { formData, onChange, error } = props;
    const handleChange = (e) => {
      const {name, value} = e.target;
      onChange({
        target: {
          name,
          value
        }
      })
    }
    return (
      <>
        {formData?.property_condition === "ready_to_use" && (
          <Flex justify="space-between" mb={4}>
            <Box flex="1">
              <ValidatedInput
                name="total_cabins"
                label="Total Cabins"
                placeholder="Enter total cabins"
                type="number"
                valueInNumber={true}
                onChange={(e)=>handleChange(e)}
                isRequired={true}
                error={error?.total_cabins}
                value={formData?.total_cabins}
              />
            </Box>
            <Box flex="1" ml={4}>
              <ValidatedInput
                name="total_seats"
                label="Total Seats"
                placeholder="Enter total seats"
                type="number"
                valueInNumber={true}
                onChange={(e)=>handleChange(e)}
                isRequired={true}
                error={error?.total_seats}
                value={formData?.total_seats}
              />
            </Box>
          </Flex>
        )}
      </>
    );
  },
  total_meeting_rooms: (props) => {
    const { formData, onChange, error } = props;
    return (
      <>
        {formData?.property_condition === "ready_to_use" && (
          <Box flex="1" ml={4}>
          <ValidatedInput
            name="total_meeting_rooms"
            label="Total Meeting Rooms"
            placeholder="Enter meeting rooms"
            type="number"
            valueInNumber={true}
            onChange={onChange}
            isRequired={true}
            error={error}
            value={formData?.total_meeting_rooms}
          />
        </Box>
        )}
      </>
    );
  },
  conference_room_available_and_conference_room: (props) => {
    const { onChange, formData } = props;
    const is_conference_room_available =
      formData?.office_features?.conference_room_available;
    const handleChange = (e) => {
      const { name, checked, value } = e.target;
      const updatedValue = {
        ...formData?.office_features,
        [name]: name === "conference_room_available" ? checked : value,
      };
      onChange({
        target: {
          name: "office_features",
          value: updatedValue,
        },
      });
    };
    return (
      <>
      {formData?.property_condition === "ready_to_use" &&
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={is_conference_room_available ? 2 : 0}>
          <CheckboxComponent
            options={conference_room_available}
            name="conference_room_available"
            onChange={(e) => handleChange(e)}
            textSize="lg"
            checkboxSize="lg"
            {...(formData?.office_features?.conference_room_available && {selectedValues: "true" })}
          />
        </Box>
        {is_conference_room_available && (
          <>
            <Box flex="1" ml={2}>
              <SelectInput
                name="conference_room"
                label="Conference Room"
                options={LEASE_YEARS}
                onChange={(e) => handleChange(e)}
                isRequired={true}
                selectedValue={formData?.office_features?.conference_room}
              />
            </Box>
          </>
        )}
      </Flex>
      }
      </>
    );
  },
  furnishing_and_ups: (props) => {
    const { onChange, formData } = props;
    const handleChange = (e) => {
      const { name, checked } = e.target;
      const updatedValue = {
        ...formData?.office_features,
        [name]: checked,
      };
      onChange({
        target: {
          name: "office_features",
          value: updatedValue,
        },
      });
    };
    return (
      <>
      {formData?.property_condition === "ready_to_use" &&
      <Flex justify="space-between" mb={4}>
        <Box flex="1">
          <CheckboxComponent
            options={furnishing}
            name="furnishing"
            onChange={(e) => handleChange(e)}
            textSize="lg"
            checkboxSize="lg"
            {...(formData?.office_features?.furnishing && {selectedValues: "true"})}
          />
        </Box>
        <Box flex="1" ml={2}>
          <CheckboxComponent
            options={ups}
            name="ups"
            onChange={(e) => handleChange(e)}
            textSize="lg"
            checkboxSize="lg"
            {...(formData?.office_features?.ups && {selectedValues: "true"})}
          />
        </Box>
      </Flex>
      }
      </>
    );
  },
  bathrooms: (props) => {
    const { onChange, formData } = props;
    const is_boathroom_available = formData?.office_features?.bathrooms_available;
    const isOffice = formData?.property_type === PROPERTY_TYPE_TAGS.office;
    const handleChange = (e) => {
      const { name, checked, value } = e.target;
      const updatedValue = {
        ...formData?.office_features,
        [name]: name === "bathrooms_available" ? checked : value,
      };
      onChange({
        target: {
          name: "office_features",
          value: updatedValue,
        },
      });
    };
    return (
      <>
      {
      isOffice &&
      <Flex gap={2}>
        <Box ml={1}>
          <CheckboxComponent
            options={bathrooms_available}
            name="bathrooms_available"
            onChange={(e) => handleChange(e)}
            textSize="lg"
            checkboxSize="lg"
            {...(formData?.office_features?.bathrooms_available && {selectedValues: "true"})}
            
          />
        </Box>
        {is_boathroom_available && (
          <>
            <Box ml={1}>
              <SelectInput
                name="private_washroom"
                label="Private Washroom"
                options={BATHROOMS}
                selectedValue={formData?.office_features?.private_washroom}
                onChange={(e) => handleChange(e)}
              />
            </Box>
            <Box ml={1}>
              <SelectInput
                name="public_washroom"
                label="Public Washroom"
                options={BATHROOMS}
                selectedValue={formData?.office_features?.public_washroom}
                onChange={(e) => handleChange(e)}
              />
            </Box>
          </>
        )}
      </Flex>
    }
    </>
    );
  },
  reception_area_and_central_ac_and_oxygen_duct: (props) => {
    const { onChange, formData } = props;
    const isOffice = formData?.property_type === PROPERTY_TYPE_TAGS.office;
    const handlChange = (e) => {
      const { name, checked } = e.target;
      const updatedValue = {
        ...formData?.office_features,
        [name]: checked,
      };
      onChange({
        target: {
          name: "office_features",
          value: updatedValue,
        },
      });
    };
    return (
      <>
      {isOffice &&
      <Flex gap={10}>
        <Box ml={1}>
          <CheckboxComponent
            options={reception_area}
            name="reception_area"
            onChange={(e) => handlChange(e)}
            textSize="lg"
            checkboxSize="lg"
            {...(formData?.office_features?.reception_area && {selectedValues: "true"})}
          />
        </Box>
        <Box ml={1}>
          <CheckboxComponent
            options={central_ac}
            name="central_ac"
            onChange={(e) => handlChange(e)}
            textSize="lg"
            checkboxSize="lg"
            {...(formData?.office_features?.central_ac && {selectedValues: "true"})}
          />
        </Box>
        <Box ml={1}>
          <CheckboxComponent
            options={oxygen_duct}
            name="oxygen_duct"
            onChange={(e) => handlChange(e)}
            textSize="lg"
            checkboxSize="lg"
            {...(formData?.office_features?.oxygen_duct && {selectedValues: "true"})}
          />
        </Box>
      </Flex>   
    }
    </>
    );
  },
  pantry_available_and_pantry: (props) => {
    const { onChange, formData, error } = props;
    const isPantryAvailable = formData?.office_features?.pantry_available;
    const isOffice = formData?.property_type === PROPERTY_TYPE_TAGS.office;
    const handleChange = (e) => {
      const { name, checked, value } = e.target;
      const updatedValue = {
        ...formData.office_features,
        [name]: name === "pantry_available" ? checked : value,
      };
      onChange({
        target: {
          name: "office_features",
          value: updatedValue,
        },
      });
    };
    return (
      <>
      {
        isOffice &&
      <Flex justify="space-between" mb={isPantryAvailable ? 2 : 0}>
        <Box flex="2">
          <CheckboxComponent
            options={PANTRY_AVAILABLE}
            name="pantry_available"
            onChange={(e) => handleChange(e)}
            textSize="lg"
            checkboxSize="lg"
            error={error?.pantry_available}
            {...(formData?.office_features?.pantry_available && {selectedValues: "true"})}
          />
        </Box>
        {isPantryAvailable && (
          <Box flex="2">
            <CheckboxComponent
              options={PANTRY_TAGS}
              name="pantry"
              onChange={(e) => handleChange(e)}
              textSize="lg"
              checkboxSize="lg"
              error={error?.pantry}
              isRequired={true}
              selectedValues={formData?.office_features?.pantry}
            />
          </Box>
        )}
      </Flex>
      }
      </>
    );
  },
  pantry_size_and_pantry_size_label: (props) => {
    const { onChange, formData, error } = props;
    const isOffice = formData?.property_type === PROPERTY_TYPE_TAGS.office;
    const handleChange = (e) => {
      const { name, value } = e.target;
      const updatedValue = {
        ...formData.office_features,
        [name]: value,
      };
      onChange({
        target: {
          name: "office_features",
          value: updatedValue,
        },
      });
    };
    return (
      <>
      {
      isOffice &&
      <Flex>
        <div style={{ flex: 3 }}>
          <ValidatedInput
            name="pantry_size"
            placeholder="Enter the pantry size"
            type="number"
            onChange={(e) => handleChange(e)}
            label="Pantry Size"
            valueInNumber={true}
            error={error?.pantry_size}
            value={formData?.office_features?.pantry_size}
          />
        </div>
        <div style={{ flex: 2 }}>
          <SelectInput
            name="pantry_size_label"
            label="Unit"
            options={AREA_UNIT}
            onChange={(e) => handleChange(e)}
            error={error?.pantry_size_label}
            selectedValue={formData?.office_features?.pantry_size_label}
          />
        </div>
      </Flex>
    }
    </>
    );
  },
  passenger_lift_and_service_lift: (props) => {
    const { onChange, formData, error } = props;
    const isOffice = formData?.property_type === PROPERTY_TYPE_TAGS.office;
    const handleChange = (e) => {
      const { name, value } = e.target;
      const updatedValue = {
        ...formData?.office_features,
        [name]: value,
      };
      onChange({
        target: {
          name: "office_features",
          value: updatedValue,
        },
      });
    };
    return (
      <>
      {
      isOffice &&
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={2}>
          <SelectInput
            name="passenger_lift"
            label="Passenger Lift"
            options={LEASE_YEARS}
            onChange={(e) => handleChange(e)}
            isRequired={true}
            error={error?.passenger_lift}
            selectedValue={formData?.office_features?.passenger_lift}
          />
        </Box>
        <Box flex="1" ml={2}>
          <SelectInput
            name="service_lift"
            label="Service Lift"
            options={LEASE_YEARS}
            onChange={(e) => handleChange(e)}
            isRequired={true}
            error={error?.service_lift}
            selectedValue={formData?.office_features?.service_lift}
          />
        </Box>
      </Flex>
    }
    </>
    );
  },
  parking_type_and_private_parking_basement: (props) => {
    const { onChange, formData, error } = props;
    const isPrkingAvailable = formData?.office_features?.parking_type === "available";
    const isOffice = formData?.property_type === PROPERTY_TYPE_TAGS.office;
    const handleChange = (e) => {
      const { name, checked, value } = e.target;
      const updatedValue = {
        ...formData.office_features,
        [name]: name === "private_parking_basement" ? checked : value,
      };
      onChange({
        target: {
          name: "office_features",
          value: updatedValue,
        },
      });
    };
    return (
      <>
      {isOffice &&
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={isPrkingAvailable ? 2 : 0}>
          <CheckboxComponent
            options={PARKING_TYPE_TAGS}
            name="parking_type"
            onChange={(e) => handleChange(e)}
            label="Parking Type"
            textSize="lg"
            checkboxSize="lg"
            error={error?.parking_type}
            isRequired={true}
            selectedValues={formData?.office_features?.parking_type}
          />
        </Box>
        {isPrkingAvailable && (
          <Box flex="1" ml={2}>
            <CheckboxComponent
              options={private_parking_basement}
              name="private_parking_basement"
              onChange={(e) => handleChange(e)}
              textSize="lg"
              checkboxSize="lg"
              error={error?.private_parking_basement}
              {...(formData?.office_features?.private_parking_basement && {selectedValues: "true"})}
            />
          </Box>
        )}
      </Flex>
      }
      </>
    );
  },
  private_parking_outside_and_public_parking: (props) => {
    const { onChange, formData, error } = props;
    const isPrkingAvailable = formData?.office_features?.parking_type === "available";
    const handleChange = (e) => {
      const { name, checked } = e.target;
      const updatedValue = {
        ...formData.office_features,
        [name]: checked,
      };
      onChange({
        target: {
          name: "office_features",
          value: updatedValue,
        },
      });
    };
    return (
      <>
        {isPrkingAvailable && (
          <Flex justify="space-between" mb={4}>
            <Box flex="1" mr={2}>
              <CheckboxComponent
                options={private_parking_outside}
                name="private_parking_outside"
                onChange={(e) => handleChange(e)}
                textSize="lg"
                checkboxSize="lg"
                error={error?.private_parking_outside}
                {...(formData?.office_features?.private_parking_outside && {selectedValues: "true"})}
              />
            </Box>
            <Box flex="1" ml={2}>
              <CheckboxComponent
                options={public_parking}
                name="public_parking"
                onChange={(e) => handleChange(e)}
                textSize="lg"
                checkboxSize="lg"
                error={error?.public_parking}
                {...(formData?.office_features?.public_parking && {selectedValues: "true"})}
              />
            </Box>
          </Flex>
        )}
      </>
    );
  },
  parking: (props) => {
    const { formData, onChange } = props;
    const isParkingAvailable =
      formData?.office_features?.parking_type === "available";
    const handleChange = (e) => {
      const { name, value } = e.target;
      const updatedValue = {
        ...formData.office_features,
        [name]: value,
      };
      onChange({
        target: {
          name: "office_features",
          value: updatedValue,
        },
      });
    };
    return (
      <>
        {isParkingAvailable && (
          <ValidatedInput
            name="parking"
            placeholder="Enter No. of Parking"
            type="number"
            label="Parking"
            valueInNumber={true}
            onChange={(e) => handleChange(e)}
            rightElement="parkings"
            value={formData?.office_features?.parking}
          />
        )}
      </>
    );
  },
  is_on_lease_and_approx_price: (props) => {
    const { onChange, formData, error } = props;
    const isSell = formData?.ad_type === AD_TYPE_TAGS.SELL;
    const label =
      formData?.is_on_lease === true ? "Lease Amount (Rs)" : isSell ? "Expected Price (Rs)" : "Rent Amount (Rs)";
    const placeholder =
      formData?.is_on_lease === true
        ? "Enter lease amount"
        : "Enter rent amount";
    const isRent = formData?.ad_type === AD_TYPE_TAGS.RENT;
    const handlChange = (e) => {
      const { name, value } = e.target;
      const updatedValue = value === "only_lease" ? true : false;
      onChange({
        target: {
          name: name,
          value: name === "is_on_lease" ? updatedValue : value,
        },
      });
    };
    return (
      <Flex justify="space-between" mb={4}>
        {isRent && (
          <Box flex="1" mr="2">
            <CheckboxComponent
              options={RENT_TYPE}
              name="is_on_lease"
              onChange={(e) => handlChange(e)}
              label="You are going to?"
              textSize="lg"
              checkboxSize="lg"
              isRequired={true}
              {...(formData?.is_on_lease !== undefined && {
                selectedValues: formData.is_on_lease
                  ? "only_lease"
                  : "only_rent",
              })}
              error={error?.is_on_lease}
            />
          </Box>
        )}
        <Box flex="1" ml={isRent ? 2 : 0}>
          <ValidatedInput
            name="approx_price"
            placeholder={placeholder}
            label={label}
            type="number"
            isRequired={true}
            valueInNumber={true}
            onChange={(e) => handlChange(e)}
            error={error?.approx_price}
            value={formData?.approx_price}
          />
        </Box>
      </Flex>
    );
  },
  maintenance_charges_and_yearly_appreciation: (props) => {
    const { onChange, formData, error } = props;
    const isRent = formData?.ad_type === AD_TYPE_TAGS.RENT;
    const isShell = formData?.ad_type === AD_TYPE_TAGS.SHELL;
    const isOffice = formData?.property_type === PROPERTY_TYPE_TAGS.office;
    const isWareHouse = formData.property_type === PROPERTY_TYPE_TAGS.warehouse;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({
        target: {
          name,
          value,
        },
      });
    };
    return (
      <Flex justify="space-between" mb={4}>
        {isRent && !formData.is_on_lease && isOffice && (
          <Box flex="2">
            <ValidatedInput
              name="yearly_appreciation"
              placeholder="% increase by"
              type="number"
              onChange={(e) => handleChange(e)}
              label="Yearly rent is expected to increase"
              isRequired={true}
              valueInNumber={true}
              error={error?.yearly_appreciation}
              value={formData?.yearly_appreciation}
            />
          </Box>
        )}
        { (isRent || (isShell && !isWareHouse)) && 
        <Box flex="2" mr={formData?.is_on_lease ? 0 : 2} ml={4}>
          <ValidatedInput
            name="maintenance_charges"
            placeholder="Enter maintenance charges"
            type="number"
            onChange={(e) => handleChange(e)}
            label="Maintenance Charges (per month)"
            valueInNumber={true}
            error={error?.maintenance_charges}
            value={formData?.maintenance_charges}
          />
        </Box>}
      </Flex>
    );
  },
  booking_amount_and_annual_dues_payable: (props) => {
    const {formData, error} = props;
    const isRent = formData?.ad_type === AD_TYPE_TAGS.RENT;
    return (
      <Flex justify="space-between" mb={4}>
        <Box flex="2">
        <ValidatedInput
      name={isRent ? "booking_amount" : "annual_dues_payable"}
      placeholder={isRent ? "Enter booking amount" : "Enter annual dues"}
      type="number"
      onChange={props.onChange}
      label={isRent ? "Booking Amount (Rs)" : "Annual dues payable (Rs)"}
      valueInNumber={true}
      error={isRent ? error?.booking_amount : error?.annual_dues_payable}
      value={isRent ? formData?.booking_amount : formData?.annual_dues_payable}
    />
        </Box>
        {!isRent && <Box flex="2"> 
        <ValidatedInput
      name="booking_amount"
      placeholder="Enter booking amount"
      type="number"
      onChange={props.onChange}
      label="Booking amount"
      valueInNumber={true}
      error={error?.booking_amount}
      value={formData?.booking_amount}
    />
        </Box>}
      </Flex>
    )
  },
  other_charges: (props) => (
    <ValidatedInput
      name="other_charges"
      value={props.value}
      placeholder="Enter other charges"
      type="number"
      onChange={props.onChange}
      label="Other Charges (Rs)"
      valueInNumber={true}
      error={props?.error}
    />
  ),
  negotiable: (props) => {
    const { onChange, formData } = props;
    const isRent = formData?.ad_type === AD_TYPE_TAGS.RENT;
    const isShell = formData?.ad_type === AD_TYPE_TAGS.SELL;
    const isOffice = formData?.property_type === PROPERTY_TYPE_TAGS.office;
    const handleChange = (e) => {
      const { name, checked } = e.target;
      onChange({
        target: {
          name,
          value: checked,
        },
      });
    };
    return (
      <Flex gap={1}>
        <div style={{ flex: 1 }}>
          <CheckboxComponent
            options={is_price_negotiable}
            name="negotiable"
            onChange={(e) => handleChange(e)}
            textSize="lg"
            checkboxSize="lg"
            {...(formData?.negotiable && {selectedValues: "true"})}
          />
        </div>
        {(isRent || (isShell && isOffice)) && <div style={{ flex: 1 }}>
          <CheckboxComponent
            options={isRent ? is_electricity_and_water_charge : ups_and_dg_price_included}
            name={isRent ? "is_electricity_and_water_charge" : "ups_and_dg_price_included"}
            onChange={(e) => handleChange(e)}
            textSize="lg"
            checkboxSize="lg"
            {...(formData?.is_electricity_and_water_charge ? {selectedValues: "true"} : formData?.ups_and_dg_price_included && {selectedValues: "true"})}

          />
        </div>}
        {!isRent && (
          <div style={{ flex: 1 }}>
            <CheckboxComponent
              options={tax_and_govt_charges_excluded}
              name="tax_and_govt_charges_excluded"
              onChange={(e) => handleChange(e)}
              textSize="lg"
              checkboxSize="lg"
              {...(formData?.tax_and_govt_charges_excluded && {selectedValues: "true"})}
            />
          </div>
        )}
      </Flex>
    );
  },
  lock_in_period_and_lease_years: (props) => {
    const { formData, onChange } = props;
    const isRent = formData?.ad_type === AD_TYPE_TAGS.RENT;
    const isOffice = formData?.property_type === PROPERTY_TYPE_TAGS.office;
    const handleChange = (e) => {
      const { name, value: updatedValue, checked } = e.target;
      const updatedData = {
        ...formData.lock_in_period,
        [name]: updatedValue,
      };
      onChange({
        target: {
          name: (name === "lease_years" || name === "is_hot_deal") ? name : "lock_in_period",
          value: name === "lease_years" ? updatedValue : name === "is_hot_deal" ? checked : updatedData,
        },
      });
    };
    return (
      <>
        {formData.is_on_lease === true ? (
          <SelectInput
            name="lease_years"
            label="No. of years to Lease"
            options={LEASE_YEARS}
            selectedValue={formData?.lease_years}
            onChange={(e) => handleChange(e)}
            isRequired={true}
          />
        ) : (
          isRent ?
          isOffice ? <Flex justify="space-between" mb={4}>
            <Box flex="1" mr={2}>
              <SelectInput
                name="type"
                label="Lock in Period"
                options={LOCK_IN_PERIOD}
                propertyType="lock_in_period"
                selectedValue={formData?.lock_in_period?.type}
                onChange={(e) => handleChange(e)}
              />
            </Box>
            {formData?.lock_in_period?.type === "custom" && (
              <Box flex="1" ml={2}>
                <ValidatedInput
                  name="period"
                  value={formData?.lock_in_period?.period}
                  placeholder="Enter no. of months"
                  type="number"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  label="Months (max 30)"
                  isRequired={true}
                  valueInNumber={true}
                />
              </Box>
            )}
          </Flex> : '' : 
          <Box>
          <CheckboxComponent
            options={IS_HOT_DEAL}
            name="is_hot_deal"
            onChange={(e) => {
              handleChange(e);
            }}
            label="Hot Deal"
            textSize="lg"
            checkboxSize="lg"
            {...(formData?.is_hot_deal && {selectedValues: "true"})}
          />
        </Box>
        )}
      </>
    );
  },
  security_deposit: (props) => {
    const { formData, onChange, error } = props;
    const isRent = formData?.ad_type === AD_TYPE_TAGS.RENT;
    const isOthers = formData?.property_type === PROPERTY_TYPE_TAGS.commercial_other;
    const handleChange = (e) => {
      const { name, value: updatedValue } = e.target;
      const updatedvalue = {
        ...formData.security_deposit,
        ...(name === "type" && { type: updatedValue }),
        ...(name === "amount" && { amount: Number(updatedValue) }),
        ...(name === "month" && {
          amount: formData.approx_price * Number(updatedValue),
        }),
      };
      onChange({
        target: {
          name: "security_deposit",
          value: updatedvalue,
        },
      });
    };
    return (
      <>
        {isRent && !formData.is_on_lease && !isOthers && (
          <Flex direction="row" alignItems="center" gap={1}>
            <div style={{ flex: 3 }}>
              <CheckboxComponent
                options={SECURITY_DEPOSIT_TYPE}
                name="type"
                onChange={(e) => handleChange(e)}
                label="Security Deposit"
                selectedValues={formData?.security_deposit?.type}
              />
            </div>
            <div style={{ flex: 2 }}>
              {formData?.security_deposit?.type &&
                formData?.security_deposit?.type !== "none" && (
                  <ValidatedInput
                    name={
                      formData?.security_deposit?.type === "fixed"
                        ? "amount"
                        : "month"
                    }
                    placeholder={
                      formData?.security_deposit?.type === "fixed"
                        ? "Enter the amount in rs"
                        : "Enter no. of months (max 36)"
                    }
                    type="number"
                    onChange={(e) => handleChange(e)}
                    label={
                      formData?.security_deposit?.type === "fixed"
                        ? "Amount in RS"
                        : "Months (max 36)"
                    }
                    valueInNumber={true}
                    error={error?.security_deposit}
                    isRequired={true}
                    value={formData?.security_deposit?.type === "fixed" ? formData?.security_deposit?.amount : formData?.security_deposit?.month}
                  />
                )}
            </div>
          </Flex>
        )}
      </>
    );
  },
  notice_period: (props) => {
    const { onChange, formData } = props;
    const isRent = formData?.ad_type === AD_TYPE_TAGS.RENT;
    const isOffice = formData?.property_type === PROPERTY_TYPE_TAGS.office;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({
        target: {
          name,
          value: Number(value),
        },
      });
    };
    return (
      <>
        {isRent && !formData.is_on_lease && isOffice &&  (
          <SelectInput
            name="notice_period"
            label="Notice Period (In months)"
            options={NOTICE_PERIOD}
            propertyType="notice_period"
            selectedValue={props.value}
            onChange={(e) => handleChange(e)}
          />
        )}
      </>
    );
  },
  discount: (props) => {
    const { onChange, formData, name: selectedName, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      const updatedValue = {
        ...formData.discount,
        [name]: value,
      };
      onChange({
        target: {
          name: selectedName,
          value: updatedValue,
        },
      });
    };
    return (
      <>
        {formData.is_hot_deal && (
          <>
            <Flex>
              <div style={{ flex: 3 }}>
                <ValidatedInput
                  name="amount"
                  placeholder={
                    formData?.discount?.type === DISCOUNT_TYPE.PERCENTAGE
                      ? "Enter Percentage"
                      : "Enter Amount"
                  }
                  type="number"
                  onChange={(e) => handleChange(e)}
                  label="Please Specify the type of the discount"
                  valueInNumber={true}
                  isRequired={true}
                  error={error?.amount}
                  value={formData?.discount?.amount}
                />
              </div>
              <div style={{ flex: 2 }}>
                <SelectInput
                  name="type"
                  label="Type"
                  options={DISCOUNT_TYPE_TAGS}
                  onChange={(e) => handleChange(e)}
                  isRequired={true}
                  error={error?.type}
                  selectedValue={formData?.discount?.type}
                />
              </div>
            </Flex>
            {typeof error === "string" && <ErrorMessage error={error} />}
          </>
        )}
      </>
    );
  },
  discount_validity_and_financing_available: (props) => {
    const { formData, onChange, error } = props;
    const handleChange = (e) => {
      const { name, checked, value } = e.target;
      onChange({
        target: {
          name: name,
          value: name === "financing_available" ? checked : value,
        },
      });
    };
    return (
      <>
        {formData.is_hot_deal && (
          <Flex gap={4}>
            <Box flex={2}>
              <CalendarInput
                name="discount_validity"
                label="Discount Validity"
                value={formData?.discount_validity}
                onChange={(e) => handleChange(e)}
                isRequired={true}
                error={error?.discount_validity}
              />
            </Box>
            <Box flex={2}>
              <CheckboxComponent
                options={FINANCING_AVAILABLE}
                name="financing_available"
                onChange={(e) => handleChange(e)}
                label="Financing Available"
                textSize="lg"
                checkboxSize="lg"
                isRequired={true}
                error={error?.financing_available}
                {...(formData?.financing_available && {selectedValues: "true"})}
              />
            </Box>
          </Flex>
        )}
      </>
    );
  },
  location: (props) => {
    const { onChange, error, formData } = props;
    const selectedValue = formData?.location?.city;
    const handleChange = (e) => {
      const { value: selectedValue } = e.target;
      const updatedValue = {
        city: selectedValue.name
      };
      onChange({
        target: {
          name: "location",
          value: updatedValue,
        },
      });
    };
    return (
      <>
        <CitySelectInput
          name="city"
          label="Location"
          onChange={(e) => handleChange(e)}
          isRequired={true}
          error={error?.city}
          selectedValue={selectedValue}
          result_type={LOCATION_TYPE.CITY}
        />
        {typeof error === "string" && <ErrorMessage error={error}/>}
      </>
    );
  },
  locality: (props) => {
    const { onChange, formData, error } = props;
    const topCities = getDataFromLocalStorage('topCities');
    const selectedValue = topCities?.find((city) => city.name === formData?.location?.city);
    const handleChange = (e) => {
      const { name, value: selectedValue } = e.target;
      if (name === "locality") {
        const data = JSON.parse(selectedValue);
        onChange({
          target: {
            name: "location",
            value: data,
          },
        });
      } else {
        onChange({
          target: {
            name: "location",
            value: {
              ...formData.location,
              [name]: selectedValue,
            },
          },
        });
      }
    };
    return (
      <>
        <Flex gap={4}>
          <div stle={{ flex: 2 }}>
            <AutoSuggestComponent
              name="locality"
              selectedValue={formData?.location?.label}
              placeholder="Search by locality, area"
              onChange={(e) => handleChange(e)}
              label="Locality (Enter area or locality address)"
              isRequired={true}
              selectedCity={formData?.location?.city ? formData.location?.city : selectedValue?.city}
              error={error}
              location={formData.location}
              result_type={LOCATION_TYPE.LOCALITY}
            />
          </div>
          <div style={{ flex: 2 }}>
            <ValidatedInput
              name="building"
              placeholder="Enter society or area"
              type="text"
              onChange={(e) => handleChange(e)}
              label="Building (Enter appartment or area)"
              value={formData?.location?.building}
            />
          </div>
        </Flex>
      </>
    );
  },
  amenities: (props) => (
    <MultiSelect
      name="amenities"
      label="Select Amenities"
      options={COMMERCIAL_AMENITIES_TAGS}
      displayValue="name"
      value={props.value}
      onChange={props.onChange}
    />
  ),
  photos: (props) => {
    const { formData, onChange, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({
        target: {
          name,
          value: value,
        },
      });
    };
    return (
      <>
        <Image
          onChange={(e) => handleChange(e)}
          name="photos"
          collectionType={formData.type}
          error={error}
          value={formData?._id ? formData?.photos : []}
        />
      </>
    );
  },
  description: (props) => {
    const { value, onChange, formData, error } = props;
    return (
      <DescriptionGenerator
        onChange={onChange}
        formData={formData}
        value={value}
        name="description"
        isRequired={true}
        label="Description"
        placeholder="Enter description or generate by AI"
        error={error}
      />
    );
  },
  slug_url: (props) => {
    const { onChange, formData, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({
        target: {
          name,
          value,
        },
      });
    };
    return (
      <>
        {formData?._id && (
          <ValidatedInput
            name="slug_url"
            label="Slug Url"
            placeholder="Add Slug Url"
            type="text"
            isRequired={true}
            onChange={(e) => handleChange(e)}
            value={formData.slug_url}
            error={error}
          />
        )}
      </>
    );
  },
  meta_title: (props) => {
    const { onChange, formData, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({ target: { name, value } });
    };
    return (
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={2}>
          <ValidatedInput
            name="meta_title"
            label="Meta Title"
            placeholder="Project name"
            type="text"
            onChange={(e) => handleChange(e)}
            error={error}
            value={formData?.meta_title}
            styles={{ height: "60px" }}
          />
        </Box>
      </Flex>
    );
  },
  meta_description: (props) => {
    const { onChange, formData, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({ target: { name, value } });
    };
    return (
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={2}>
          <ValidatedInput
            name="meta_description"
            label="Meta Description"
            placeholder="Enter about"
            type="text"
            onChange={(e) => handleChange(e)}
            error={error}
            value={formData?.meta_description}
            styles={{ height: "60px" }}
          />
        </Box>
      </Flex>
    );
  },
};

export default commercialOfficeForm;
