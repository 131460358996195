import React from "react";
import { Icon } from "@chakra-ui/react";
import { MdHome, MdGroup, MdLock, MdListAlt, MdChecklistRtl, MdFeedback, MdComment, MdReportGmailerrorred } from "react-icons/md";
import { MdSupportAgent } from "react-icons/md";
import { IoIosPaper, IoIosPeople, IoMdLocate, IoMdWifi } from "react-icons/io";
import { VscFeedback } from "react-icons/vsc";
import { FaDisplay } from "react-icons/fa6";
import MainDashboard from "views/admin/default";
import UsersTable from "views/admin/users";
import PropertiesTable from "views/admin/properties";
import BuildersTable from "views/admin/builders";
import SignInCentered from "views/auth/signIn";
import Form from "views/admin/form/Form";
import AgentsTable from "views/admin/agents";
import JobApplications from "views/admin/jobs";
import LocalityInsight from "views/admin/localityInsight";
import PropertyStat from "views/admin/properties/propertyStat/PropertyStat";
import UserActivity from "views/admin/userActivity";
import CreateBuilder from "views/admin/builders/Form";
import ProjectTable from "views/admin/project";
import UserRequirements from "views/admin/userRequirements";
import BrokerGroups from "views/admin/brokerGroups";
import UserRequests from "views/admin/userRequests";
import UserFeedbacks from "views/admin/UserFeedbacks";
import UserReports from "views/admin/userReports";
import DataCharts from "views/admin/dataCharts";
import { PiChartLineThin } from "react-icons/pi";
const routes = [
  {
    name: 'Dashboard',
    layout: '/admin',
    path: '/default',
    icon: <Icon as={MdHome} width="30px" height="30px" color="inherit" />,
    component: MainDashboard,
    authorized: ['admin', 'marketing_admin'],
  },
  {
    name: 'Ads',
    layout: '/admin',
    path: '/ads',
    icon: <Icon as={FaDisplay} width="28px" height="24px" color="inherit" />,
    component: PropertiesTable,
    authorized: ['admin', 'marketing_admin'],
  },
  {
    name: 'Project',
    layout: '/admin',
    path: '/project',
    icon: <Icon as={IoIosPeople} width="30px" height="30px" color="inherit" />,
    component: ProjectTable,
    authorized: ['admin', 'marketing_admin'],
  },
  {
    name: 'Users',
    layout: '/admin',
    path: '/users',
    icon: <Icon as={MdGroup} width="30px" height="30px" color="inherit" />,
    component: UsersTable,
    authorized: ['admin', 'hr_admin', 'marketing_admin'],
  },
  {
    name: 'Locality Insights',
    layout: '/admin',
    path: '/locality-insights',
    icon: <Icon as={IoMdLocate} width="30px" height="30px" color="inherit" />,
    component: LocalityInsight,
    authorized: ['admin', 'marketing_admin'],
  },
  {
    name: 'User Activity',
    layout: '/admin',
    path: '/user-activity',
    icon: <Icon as={IoMdWifi} width="30px" height="30px" color="inherit" />,
    component: UserActivity,
    authorized: ['admin', 'marketing_admin'],
  },
  {
    name: 'Agents',
    layout: '/admin',
    path: '/agents',
    icon: (
      <Icon as={MdSupportAgent} width="30px" height="30px" color="inherit" />
    ),
    component: AgentsTable,
    authorized: ['admin', 'marketing_admin'],
  },
  {
    name: 'Builders',
    layout: '/admin',
    path: '/builders',
    icon: <Icon as={IoIosPeople} width="30px" height="30px" color="inherit" />,
    component: BuildersTable,
    authorized: ['admin', 'marketing_admin'],
  },
  {
    name: 'Job Applications',
    layout: '/admin',
    path: '/job-applications',
    icon: <Icon as={IoIosPaper} width="30px" height="30px" color="inherit" />,
    component: JobApplications,
    authorized: ['admin', 'hr_admin'],
  },
  {
    name: 'Ads Stats',
    layout: '/admin',
    path: '/users/:userId',
    icon: <Icon as={FaDisplay} width="28px" height="24px" color="inherit" />,
    component: PropertyStat,
    authorized: ['admin'],
  },

  {
    name: 'Sign In',
    layout: '/auth',
    path: '/sign-in',
    icon: <Icon as={MdLock} width="30px" height="30px" color="inherit" />,
    component: SignInCentered,
    authorized: ['admin'],
  },
  {
    name: 'Dynamic Form',
    layout: '/admin',
    path: '/form',
    icon: <Icon as={MdLock} width="30px" heght="30px" color="inherit" />,
    component: Form,
    authorized: ['admin', 'marketing_admin'],
  },
  {
    name: 'Dynamic Builder Creation Form',
    layout: '/admin',
    path: '/builder',
    icon: <Icon as={MdLock} width="30px" heght="30px" color="inherit" />,
    component: CreateBuilder,
    authorized: ['admin', 'marketing_admin'],
  },
  {
    name: 'User Requirement',
    layout: '/admin',
    path: '/user-requirements',
    icon: <Icon as={MdChecklistRtl} width="30px" height="30px" color="inherit" />,
    component: UserRequirements,
    authorized: ['admin', 'marketing_admin'],
  },
  {
    name: 'Broker Groups',
    layout: '/admin',
    path: '/broker-groups',
    icon: <Icon as={MdListAlt} width="30px" height="30px" color="inherit" />,
    component: BrokerGroups,
    authorized: ['admin', 'marketing_admin'],
  },
  {
    name: 'User Request',
    layout: '/admin',
    path: '/user-requests',
    icon: <Icon as={MdComment} width="30px" height="30px" color="inherit" />,
    component: UserRequests,
    authorized: ['admin', 'marketing_admin'],
  },
  {
    name: 'User Feedback',
    layout: '/admin',
    path: '/user-feedbacks',
    icon: <Icon as={VscFeedback} width="30px" height="30px" color="inherit" />,
    component: UserFeedbacks,
    authorized: ['admin', 'marketing_admin'],
  },
  {
    name: 'User Reports',
    layout: '/admin',
    path: '/user-reports',
    icon: <Icon as={MdReportGmailerrorred} width="30px" height="30px" color="inherit" />,
    component: UserReports,
    authorized: ['admin', 'marketing_admin'],
  },
  {
    name: 'Data Charts',
    layout: '/admin',
    path: '/data-charts',
    icon: <Icon as={PiChartLineThin} width="30px" height="30px" color="inherit" />,
    component: DataCharts,
    authorized: ['admin', 'marketing_admin'],
  }
];

export default routes;
