
  export const LEADS_DATA_FOR_CHART = {
    data: {
       days:[
            {date: '2025-01-01', leads: 29, users: 65, property: 18},
            {date: '2025-01-02', leads: 13, users: 60, property: 31},
            {date: '2025-01-03', leads: 15, users: 66, property: 15},
            {date: '2025-01-04', leads: 6, users: 85, property: 32},
            {date: '2025-01-05', leads: 26, users: 53, property: 16},
            {date: '2025-01-06', leads: 26, users: 64, property: 36},
            {date: '2025-01-07', leads: 12, users: 89, property: 32},
            {date: '2025-01-08', leads: 17, users: 77, property: 29},
            {date: '2025-01-09', leads: 5, users: 84, property: 23},
            {date: '2025-01-10', leads: 18, users: 98, property: 17},
            {date: '2025-01-11', leads: 8, users: 53, property: 34},
            {date: '2025-01-12', leads: 21, users: 64, property: 28},
            {date: '2025-01-13', leads: 22, users: 94, property: 31},
            {date: '2025-01-14', leads: 16, users: 71, property: 17},
            {date: '2025-01-15', leads: 9, users: 98, property: 35},
            {date: '2025-01-16', leads: 26, users: 51, property: 23},
            {date: '2025-01-17', leads: 6, users: 77, property: 27},
            {date: '2025-01-18', leads: 8, users: 67, property: 23},
            {date: '2025-01-19', leads: 25, users: 71, property: 19},
            {date: '2025-01-20', leads: 18, users: 100, property: 25},
            {date: '2025-01-21', leads: 15, users: 88, property: 32},
            {date: '2025-01-22', leads: 7, users: 62, property: 22},
            {date: '2025-01-23', leads: 12, users: 96, property: 15},
            {date: '2025-01-24', leads: 12, users: 73, property: 35},
            {date: '2025-01-25', leads: 21, users: 89, property: 22},
            {date: '2025-01-26', leads: 10, users: 92, property: 19},
            {date: '2025-01-27', leads: 5, users: 60, property: 18},
            {date: '2025-01-28', leads: 22, users: 51, property: 29},
            {date: '2025-01-29', leads: 21, users: 58, property: 15},
            {date: '2025-01-30', leads: 21, users: 88, property: 28},
            {date: '2025-01-31', leads: 7, users: 69, property: 24},

            {date: '2025-02-01', leads: 12, users: 76, property: 22},
            {date: '2025-02-02', leads: 22, users: 87, property: 34},
            {date: '2025-02-03', leads: 17, users: 91, property: 33},
            {date: '2025-02-04', leads: 27, users: 75, property: 34},
            {date: '2025-02-05', leads: 19, users: 53, property: 38},
            {date: '2025-02-06', leads: 25, users: 61, property: 36},
            {date: '2025-02-07', leads: 20, users: 77, property: 21},
            {date: '2025-02-08', leads: 28, users: 91, property: 27},
            {date: '2025-02-09', leads: 22, users: 69, property: 34},
            {date: '2025-02-10', leads: 29, users: 96, property: 22},
            {date: '2025-02-11', leads: 21, users: 50, property: 23},
            {date: '2025-02-12', leads: 26, users: 58, property: 21},
            {date: '2025-02-13', leads: 23, users: 62, property: 26},
            {date: '2025-02-14', leads: 30, users: 77, property: 36},
            {date: '2025-02-15', leads: 20, users: 57, property: 33},
            {date: '2025-02-16', leads: 27, users: 72, property: 24},
            {date: '2025-02-17', leads: 22, users: 59, property: 30},
            {date: '2025-02-18', leads: 25, users: 52, property: 38},
            {date: '2025-02-19', leads: 29, users: 80, property: 24},
            {date: '2025-02-20', leads: 24, users: 69, property: 39},
            {date: '2025-02-21', leads: 28, users: 55, property: 21},
            {date: '2025-02-22', leads: 26, users: 67, property: 33},
            {date: '2025-02-23', leads: 31, users: 52, property: 25},
            {date: '2025-02-24', leads: 23, users: 74, property: 28},
            {date: '2025-02-25', leads: 27, users: 93, property: 22},
            {date: '2025-02-26', leads: 25, users: 82, property: 39},
            {date: '2025-02-27', leads: 30, users: 95, property: 23},
            {date: '2025-02-28', leads: 22, users: 97, property: 32},
            {date: '2025-03-01', leads: 14, users: 74, property: 25},
            {date: '2025-03-02', leads: 24, users: 56, property: 34},
            {date: '2025-03-03', leads: 19, users: 97, property: 24},
            {date: '2025-03-04', leads: 29, users: 72, property: 26},
            {date: '2025-03-05', leads: 21, users: 79, property: 39},
            {date: '2025-03-06', leads: 27, users: 51, property: 39},
            {date: '2025-03-07', leads: 22, users: 67, property: 26},
            {date: '2025-03-08', leads: 30, users: 82, property: 25},
            {date: '2025-03-09', leads: 24, users: 73, property: 22},
            {date: '2025-03-10', leads: 31, users: 68, property: 27},
            {date: '2025-03-11', leads: 23, users: 69, property: 36},
            {date: '2025-03-12', leads: 28, users: 77, property: 39},
            {date: '2025-03-13', leads: 25, users: 91, property: 34},
            {date: '2025-03-14', leads: 32, users: 57, property: 26},
            {date: '2025-03-15', leads: 22, users: 76, property: 31},
            {date: '2025-03-16', leads: 29, users: 86, property: 24},
            {date: '2025-03-17', leads: 24, users: 99, property: 29},
            {date: '2025-03-18', leads: 27, users: 52, property: 37},
            {date: '2025-03-19', leads: 31, users: 84, property: 35},
            {date: '2025-03-20', leads: 26, users: 55, property: 26},
            {date: '2025-03-21', leads: 30, users: 94, property: 24},
            {date: '2025-03-22', leads: 28, users: 65, property: 23},
            {date: '2025-03-23', leads: 33, users: 59, property: 35},
            {date: '2025-03-24', leads: 25, users: 78, property: 39},
            {date: '2025-03-25', leads: 29, users: 62, property: 36},
            {date: '2025-03-26', leads: 27, users: 77, property: 24},
            {date: '2025-03-27', leads: 32, users: 95, property: 32},
            {date: '2025-03-28', leads: 24, users: 66, property: 28},
            {date: '2025-03-29', leads: 30, users: 75, property: 22},
            {date: '2025-03-30', leads: 26, users: 57, property: 34},
            {date: '2025-03-31', leads: 31, users: 93, property: 36},
            {date: '2025-04-01', leads: 15, users: 64, property: 34},
            {date: '2025-04-02', leads: 25, users: 58, property: 33},
            {date: '2025-04-03', leads: 20, users: 92, property: 24},
            {date: '2025-04-04', leads: 30, users: 51, property: 39},
            {date: '2025-04-05', leads: 22, users: 69, property: 29},
            {date: '2025-04-06', leads: 28, users: 68, property: 36},
            {date: '2025-04-07', leads: 23, users: 98, property: 25},
            {date: '2025-04-08', leads: 31, users: 69, property: 33},
            {date: '2025-04-09', leads: 25, users: 73, property: 32},
            {date: '2025-04-10', leads: 32, users: 50, property: 26},
            {date: '2025-04-11', leads: 24, users: 77, property: 34},
            {date: '2025-04-12', leads: 29, users: 77, property: 23},
            {date: '2025-04-13', leads: 26, users: 84, property: 24},
            {date: '2025-04-14', leads: 33, users: 83, property: 35},
            {date: '2025-04-15', leads: 23, users: 56, property: 39},
            {date: '2025-04-16', leads: 30, users: 61, property: 25},
            {date: '2025-04-17', leads: 25, users: 57, property: 30},
            {date: '2025-04-18', leads: 28, users: 88, property: 39},
            {date: '2025-04-19', leads: 32, users: 60, property: 28},
            {date: '2025-04-20', leads: 27, users: 70, property: 23},
            {date: '2025-04-21', leads: 31, users: 62, property: 29},
            {date: '2025-04-22', leads: 29, users: 70, property: 31},
            {date: '2025-04-23', leads: 34, users: 73, property: 26},
            {date: '2025-04-24', leads: 26, users: 56, property: 34},
            {date: '2025-04-25', leads: 30, users: 79, property: 22},
            {date: '2025-04-26', leads: 28, users: 65, property: 33},
            {date: '2025-04-27', leads: 33, users: 52, property: 26},
            {date: '2025-04-28', leads: 25, users: 59, property: 33},
            {date: '2025-04-29', leads: 31, users: 82, property: 25},
            {date: '2025-04-30', leads: 27, users: 52, property: 31},
            {date: '2025-05-01', leads: 16, users: 87, property: 25},
            {date: '2025-05-02', leads: 26, users: 79, property: 23},
            {date: '2025-05-03', leads: 21, users: 73, property: 35},
            {date: '2025-05-04', leads: 31, users: 78, property: 26},
            {date: '2025-05-05', leads: 23, users: 59, property: 39},
            {date: '2025-05-06', leads: 29, users: 87, property: 24},
            {date: '2025-05-07', leads: 24, users: 91, property: 32},
            {date: '2025-05-08', leads: 32, users: 68, property: 22},
            {date: '2025-05-09', leads: 26, users: 53, property: 33},
            {date: '2025-05-10', leads: 33, users: 51, property: 37},
            {date: '2025-05-11', leads: 25, users: 57, property: 29},
            {date: '2025-05-12', leads: 30, users: 88, property: 25},
            {date: '2025-05-13', leads: 27, users: 72, property: 27},
            {date: '2025-05-14', leads: 34, users: 84, property: 37},
            {date: '2025-05-15', leads: 24, users: 57, property: 23},
            {date: '2025-05-16', leads: 31, users: 70, property: 26},
            {date: '2025-05-17', leads: 26, users: 59, property: 38},
            {date: '2025-05-18', leads: 29, users: 59, property: 30},
            {date: '2025-05-19', leads: 33, users: 53, property: 22},
            {date: '2025-05-20', leads: 28, users: 82, property: 31},
            {date: '2025-05-21', leads: 32, users: 51, property: 38},
            {date: '2025-05-22', leads: 30, users: 52, property: 39},
            {date: '2025-05-23', leads: 35, users: 62, property: 34},
            {date: '2025-05-24', leads: 27, users: 59, property: 25},
            {date: '2025-05-25', leads: 31, users: 77, property: 32},
            {date: '2025-05-26', leads: 29, users: 97, property: 25},
            {date: '2025-05-27', leads: 34, users: 53, property: 39},
            {date: '2025-05-28', leads: 26, users: 71, property: 25},
            {date: '2025-05-29', leads: 32, users: 99, property: 22},
            {date: '2025-05-30', leads: 28, users: 93, property: 39},
            {date: '2025-05-31', leads: 33, users: 81, property: 27},
            {date: '2025-06-01', leads: 17, users: 54, property: 27},
            {date: '2025-06-02', leads: 27, users: 58, property: 35},
            {date: '2025-06-03', leads: 22, users: 66, property: 27},
            {date: '2025-06-04', leads: 32, users: 51, property: 23},
            {date: '2025-06-05', leads: 24, users: 67, property: 32},
            {date: '2025-06-06', leads: 30, users: 73, property: 28},
            {date: '2025-06-07', leads: 25, users: 52, property: 23},
            {date: '2025-06-08', leads: 33, users: 53, property: 30},
            {date: '2025-06-09', leads: 27, users: 60, property: 28},
            {date: '2025-06-10', leads: 34, users: 82, property: 23},
            {date: '2025-06-11', leads: 26, users: 80, property: 37},
            {date: '2025-06-12', leads: 31, users: 78, property: 25},
            {date: '2025-06-13', leads: 28, users: 73, property: 33},
            {date: '2025-06-14', leads: 35, users: 51, property: 25},
            {date: '2025-06-15', leads: 25, users: 72, property: 32},
            {date: '2025-06-16', leads: 32, users: 57, property: 23},
            {date: '2025-06-17', leads: 27, users: 59, property: 26},
            {date: '2025-06-18', leads: 30, users: 53, property: 36},
            {date: '2025-06-19', leads: 34, users: 51, property: 38},
            {date: '2025-06-20', leads: 29, users: 74, property: 36},
            {date: '2025-06-21', leads: 33, users: 62, property: 35},
            {date: '2025-06-22', leads: 31, users: 70, property: 28},
            {date: '2025-06-23', leads: 36, users: 52, property: 30},
            {date: '2025-06-24', leads: 28, users: 67, property: 34},
            {date: '2025-06-25', leads: 32, users: 51, property: 36},
            {date: '2025-06-26', leads: 30, users: 73, property: 24},
            {date: '2025-06-27', leads: 35, users: 53, property: 24},
            {date: '2025-06-28', leads: 27, users: 67, property: 33},
            {date: '2025-06-29', leads: 33, users: 78, property: 34},
            {date: '2025-06-30', leads: 29, users: 73, property: 35},
            {date: '2025-07-01', leads: 18, users: 51, property: 32},
            {date: '2025-07-02', leads: 28, users: 73, property: 26},
            {date: '2025-07-03', leads: 23, users: 94, property: 34},
            {date: '2025-07-04', leads: 33, users: 76, property: 26},
            {date: '2025-07-05', leads: 25, users: 54, property: 34},
            {date: '2025-07-06', leads: 31, users: 58, property: 24},
            {date: '2025-07-07', leads: 26, users: 65, property: 38},
            {date: '2025-07-08', leads: 34, users: 51, property: 32},
            {date: '2025-07-09', leads: 28, users: 62, property: 24},
            {date: '2025-07-10', leads: 35, users: 69, property: 36},
            {date: '2025-07-11', leads: 27, users: 55, property: 32},
            {date: '2025-07-12', leads: 32, users: 68, property: 29},
            {date: '2025-07-13', leads: 29, users: 52, property: 33},
            {date: '2025-07-14', leads: 36, users: 68, property: 24},
            {date: '2025-07-15', leads: 26, users: 69, property: 26},
            {date: '2025-07-16', leads: 33, users: 51, property: 25},
            {date: '2025-07-17', leads: 28, users: 68, property: 25},
            {date: '2025-07-18', leads: 31, users: 73, property: 32},
            {date: '2025-07-19', leads: 35, users: 64, property: 29},
            {date: '2025-07-20', leads: 30, users: 52, property: 29},
            {date: '2025-07-21', leads: 34, users: 51, property: 24},
            {date: '2025-07-22', leads: 32, users: 70, property: 32},
            {date: '2025-07-23', leads: 37, users: 58, property: 30},
            {date: '2025-07-24', leads: 29, users: 59, property: 36},
            {date: '2025-07-25', leads: 33, users: 51, property: 27},
            {date: '2025-07-26', leads: 31, users: 68, property: 27},
            {date: '2025-07-27', leads: 36, users: 53, property: 24},
            {date: '2025-07-28', leads: 28, users: 68, property: 35},
            {date: '2025-07-29', leads: 34, users: 51, property: 32},
            {date: '2025-07-30', leads: 30, users: 69, property: 26},
            {date: '2025-07-31', leads: 35, users: 52, property: 30},
            {date: '2025-08-01', leads: 19, users: 51, property: 24},
            {date: '2025-08-02', leads: 29, users: 67, property: 32},
            {date: '2025-08-03', leads: 24, users: 52, property: 27},
            {date: '2025-08-04', leads: 34, users: 51, property: 28},
            {date: '2025-08-05', leads: 26, users: 68, property: 30},
            {date: '2025-08-06', leads: 32, users: 51, property: 34},
            {date: '2025-08-07', leads: 27, users: 53, property: 24},
            {date: '2025-08-08', leads: 35, users: 51, property: 32},
            {date: '2025-08-09', leads: 29, users: 68, property: 25},
            {date: '2025-08-10', leads: 36, users: 51, property: 35},
            {date: '2025-08-11', leads: 28, users: 52, property: 33},
            {date: '2025-08-12', leads: 33, users: 51, property: 27},
            {date: '2025-08-13', leads: 30, users: 68, property: 26},
            {date: '2025-08-14', leads: 37, users: 51, property: 30},
            {date: '2025-08-15', leads: 27, users: 52, property: 26},
            {date: '2025-08-16', leads: 34, users: 51, property: 28},
            {date: '2025-08-17', leads: 29, users: 68, property: 29},
            {date: '2025-08-18', leads: 32, users: 51, property: 31},
            {date: '2025-08-19', leads: 36, users: 52, property: 33},
            {date: '2025-08-20', leads: 31, users: 51, property: 29},
            {date: '2025-08-21', leads: 35, users: 52, property: 30},
            {date: '2025-08-22', leads: 33, users: 68, property: 32},
            {date: '2025-08-23', leads: 38, users: 51, property: 33},
            {date: '2025-08-24', leads: 30, users: 52, property: 26},
            {date: '2025-08-25', leads: 34, users: 51, property: 30},
            {date: '2025-08-26', leads: 32, users: 52, property: 32},
            {date: '2025-08-27', leads: 37, users: 51, property: 29},
            {date: '2025-08-28', leads: 29, users: 68, property: 28},
            {date: '2025-08-29', leads: 35, users: 51, property: 30},
            {date: '2025-08-30', leads: 31, users: 52, property: 32},
            {date: '2025-08-31', leads: 36, users: 51, property: 30},
            {date: '2025-09-01', leads: 20, users: 51, property: 32},
            {date: '2025-09-02', leads: 30, users: 52, property: 25},
            {date: '2025-09-03', leads: 25, users: 68, property: 29},
            {date: '2025-09-04', leads: 35, users: 51, property: 30},
            {date: '2025-09-05', leads: 27, users: 52, property: 32},
            {date: '2025-09-06', leads: 33, users: 51, property: 30},
            {date: '2025-09-07', leads: 28, users: 68, property: 28},
            {date: '2025-09-08', leads: 36, users: 51, property: 31},
            {date: '2025-09-09', leads: 30, users: 52, property: 32},
            {date: '2025-09-10', leads: 37, users: 51, property: 33},
            {date: '2025-09-11', leads: 29, users: 68, property: 29},
            {date: '2025-09-12', leads: 34, users: 51, property: 30},
            {date: '2025-09-13', leads: 31, users: 52, property: 32},
            {date: '2025-09-14', leads: 38, users: 51, property: 31},
            {date: '2025-09-15', leads: 28, users: 68, property: 29},
            {date: '2025-09-16', leads: 35, users: 51, property: 30},
            {date: '2025-09-17', leads: 30, users: 52, property: 32},
            {date: '2025-09-18', leads: 33, users: 51, property: 31},
            {date: '2025-09-19', leads: 37, users: 52, property: 33},
            {date: '2025-09-20', leads: 32, users: 51, property: 29},
            {date: '2025-09-21', leads: 36, users: 68, property: 30},
            {date: '2025-09-22', leads: 34, users: 51, property: 32},
            {date: '2025-09-23', leads: 39, users: 52, property: 31},
            {date: '2025-09-24', leads: 31, users: 51, property: 33},
            {date: '2025-09-25', leads: 35, users: 52, property: 30},
            {date: '2025-09-26', leads: 33, users: 51, property: 32},
            {date: '2025-09-27', leads: 38, users: 52, property: 31},
            {date: '2025-09-28', leads: 30, users: 51, property: 33},
            {date: '2025-09-29', leads: 36, users: 68, property: 29},
            {date: '2025-09-30', leads: 32, users: 51, property: 30},
            {date: '2025-10-01', leads: 21, users: 51, property: 32},
            {date: '2025-10-02', leads: 31, users: 52, property: 26},
            {date: '2025-10-03', leads: 26, users: 68, property: 29},
            {date: '2025-10-04', leads: 36, users: 51, property: 30},
            {date: '2025-10-05', leads: 28, users: 52, property: 32},
            {date: '2025-10-06', leads: 34, users: 51, property: 29},
            {date: '2025-10-07', leads: 29, users: 68, property: 30},
            {date: '2025-10-08', leads: 37, users: 51, property: 32},
            {date: '2025-10-09', leads: 31, users: 52, property: 31},
            {date: '2025-10-10', leads: 38, users: 51, property: 33},
            {date: '2025-10-11', leads: 30, users: 52, property: 29},
            {date: '2025-10-12', leads: 35, users: 51, property: 30},
            {date: '2025-10-13', leads: 32, users: 52, property: 32},
            {date: '2025-10-14', leads: 39, users: 51, property: 29},
            {date: '2025-10-15', leads: 29, users: 68, property: 30},
            {date: '2025-10-16', leads: 36, users: 51, property: 32},
            {date: '2025-10-17', leads: 31, users: 52, property: 31},
            {date: '2025-10-18', leads: 34, users: 51, property: 33},
            {date: '2025-10-19', leads: 38, users: 52, property: 29},
            {date: '2025-10-20', leads: 33, users: 51, property: 30},
            {date: '2025-10-21', leads: 37, users: 52, property: 32},
            {date: '2025-10-22', leads: 35, users: 51, property: 31},
            {date: '2025-10-23', leads: 40, users: 52, property: 33},
            {date: '2025-10-24', leads: 32, users: 51, property: 29},
            {date: '2025-10-25', leads: 36, users: 52, property: 30},
            {date: '2025-10-26', leads: 34, users: 51, property: 32},
            {date: '2025-10-27', leads: 39, users: 52, property: 29},
            {date: '2025-10-28', leads: 31, users: 51, property: 30},
            {date: '2025-10-29', leads: 37, users: 52, property: 32},
            {date: '2025-10-30', leads: 33, users: 51, property: 31},
            {date: '2025-10-31', leads: 38, users: 52, property: 33},
            {date: '2025-11-01', leads: 22, users: 51, property: 32},
            {date: '2025-11-02', leads: 32, users: 52, property: 27},
            {date: '2025-11-03', leads: 27, users: 68, property: 30},
            {date: '2025-11-04', leads: 37, users: 51, property: 29},
            {date: '2025-11-05', leads: 29, users: 52, property: 32},
            {date: '2025-11-06', leads: 35, users: 51, property: 30},
            {date: '2025-11-07', leads: 30, users: 52, property: 32},
            {date: '2025-11-08', leads: 38, users: 51, property: 29},
            {date: '2025-11-09', leads: 32, users: 52, property: 30},
            {date: '2025-11-10', leads: 39, users: 51, property: 32},
            {date: '2025-11-11', leads: 31, users: 52, property: 31},
            {date: '2025-11-12', leads: 36, users: 51, property: 33},
            {date: '2025-11-13', leads: 33, users: 52, property: 29},
            {date: '2025-11-14', leads: 40, users: 51, property: 30},
            {date: '2025-11-15', leads: 30, users: 52, property: 32},
            {date: '2025-11-16', leads: 37, users: 51, property: 29},
            {date: '2025-11-17', leads: 32, users: 52, property: 30},
            {date: '2025-11-18', leads: 35, users: 51, property: 32},
            {date: '2025-11-19', leads: 39, users: 52, property: 29},
            {date: '2025-11-20', leads: 34, users: 51, property: 30},
            {date: '2025-11-21', leads: 38, users: 52, property: 32},
            {date: '2025-11-22', leads: 36, users: 51, property: 31},
            {date: '2025-11-23', leads: 41, users: 52, property: 33},
            {date: '2025-11-24', leads: 33, users: 51, property: 29},
            {date: '2025-11-25', leads: 37, users: 52, property: 30},
            {date: '2025-11-26', leads: 35, users: 51, property: 32},
            {date: '2025-11-27', leads: 40, users: 52, property: 29},
            {date: '2025-11-28', leads: 32, users: 51, property: 30},
            {date: '2025-11-29', leads: 38, users: 52, property: 32},
            {date: '2025-11-30', leads: 34, users: 51, property: 31},
            {date: '2025-12-01', leads: 23, users: 51, property: 32},
            {date: '2025-12-02', leads: 33, users: 52, property: 28},
            {date: '2025-12-03', leads: 28, users: 68, property: 30},
            {date: '2025-12-04', leads: 38, users: 51, property: 29},
            {date: '2025-12-05', leads: 30, users: 52, property: 32},
            {date: '2025-12-06', leads: 36, users: 51, property: 29},
            {date: '2025-12-07', leads: 31, users: 52, property: 30},
            {date: '2025-12-08', leads: 39, users: 51, property: 32},
            {date: '2025-12-09', leads: 33, users: 52, property: 31},
            {date: '2025-12-10', leads: 40, users: 51, property: 29},
            {date: '2025-12-11', leads: 32, users: 68, property: 30},
            {date: '2025-12-12', leads: 37, users: 51, property: 32},
            {date: '2025-12-13', leads: 34, users: 52, property: 29},
            {date: '2025-12-14', leads: 41, users: 51, property: 30},
            {date: '2025-12-15', leads: 31, users: 52, property: 32},
            {date: '2025-12-16', leads: 38, users: 51, property: 29},
            {date: '2025-12-17', leads: 33, users: 52, property: 30},
            {date: '2025-12-18', leads: 36, users: 51, property: 32},
            {date: '2025-12-19', leads: 40, users: 52, property: 29},
            {date: '2025-12-20', leads: 35, users: 51, property: 30},
            {date: '2025-12-21', leads: 39, users: 52, property: 32},
            {date: '2025-12-22', leads: 37, users: 51, property: 29},
            {date: '2025-12-23', leads: 42, users: 52, property: 30},
            {date: '2025-12-24', leads: 34, users: 51, property: 32},
            {date: '2025-12-25', leads: 38, users: 52, property: 29},
            {date: '2025-12-26', leads: 36, users: 51, property: 30},
            {date: '2025-12-27', leads: 41, users: 52, property: 32},
            {date: '2025-12-28', leads: 33, users: 51, property: 29},
            {date: '2025-12-29', leads: 39, users: 52, property: 30},
            {date: '2025-12-30', leads: 35, users: 51, property: 32},
            {date: '2025-12-31', leads: 40, users: 52, property: 29}
       ]
        
    }
}

