export const USER_ROLES = {
  admin: "Admin",
  owner: "Owner",
  agent_dealer: "Agent",
  builder: "Builder",
  co_living: "Co_Living",
  hr_admin: "Hr Admin",
  marketing_admin: "Marketing Admin",
  bloging_admin: "Bloging Admin"
};

export const USER_ROLE = {
  OWNER: 'owner',
  AGENT_DEALER: 'agent_dealer',
  BUILDER: 'builder',
  CO_LIVING: 'co_living',
  ADMIN: 'admin',
  USER: 'user',
  MARKETING_ADMIN: "marketing_admin",
  HR_ADMIN: "hr_admin",
  BLOGING_ADMIN: "bloging_admin"
};

export const TRANSACTION_TYPE = {
  residential_property: "Residential",
  commercial_property: "Commercial",
  plot: "Plot",
  PG: "PG",
  share_flat: "Share Flat",
};

export const AD_TYPE = {
  rent: "Rent",
  sell: "Sell",
};

export const AD_TYPE_TAGS = {
  RENT: "rent",
  SELL: "sell",
};

export const PROPERTY_STATUS = {
  active: "Active",
  expired: "Expired",
  pending_verification: "Pending",
  deleted: "Deleted",
  inactive: "Inactive",
  rejected: "Rejected",
};

export const PROJECTS_ENTITY_STATUS = {
  active: "Active",
  pending_verification: "Pending",
  deleted: "Deleted",
  inactive: "Inactive",
  rejected: "Rejected",
};

export const PROPERTY_TYPE = {
  "flat/apartment": "Apartment",
  floor: "Individual Floor",
  independent_house: "Independent House",
  "vila/farm_house": "Individual Villa",
  "1_rk_studio": "1RK/Studio House",
  others: "Others",
  office: "Office Space",
  warehouse: "Warehouse",
  retail_shop: "Retail Shop",
  showroom: "Showroom",
  other: "Other",
  plot: "Plot/Land",
  need_flat: "Need Room/Flat",
  need_flatmate: "Need Roommates",
  pg_hostel: "PG/Hostel",
  ready_to_use_office_space: "Ready to use office space",
  bareshell_office_space: "Bareshell office space",
  pg: "Pg",
  share_flat: "Co-living",
  "commercial-plots-land": "Commercial Plots",
  "residential-plots-land": "Residential Plots",
  PG: "PG/Hostel",
};

export const PROPERTY_TYPE_META_TAGS = {
  "flat/apartment": "Apartment",
  floor: "Floor",
  independent_house: "House",
  "vila/farm_house": "Villa",
  "1_rk_studio": "1 RK Studio",
  others: "Others",
  office: "Office Space",
  warehouse: "Warehouse",
  retail_shop: "Retail Shop",
  showroom: "Showroom",
  other: "Other",
  plot: "Land / Plots",
  need_flat: "Flat",
  need_flatmate: "Flatmate",
  pg_hostel: "Hostel",
  ready_to_use_office_space: "Ready to move office space",
  bareshell_office_space: "Bare Shell Office Space",
  pg: "PG",
  share_flat: "FlatMates",
  "commercial-plots-land": "Commercial Plots",
  "residential-plots-land": "Residential Plots"
};

export const JOB_APPLICATION_STATUS = {
  need_to_complete_assignment: "Need To Complete Assignment",
  assignment_submitted: "Assignment Submitted",
  assignment_reviewed: "Assignment Reviewed",
  rejected: "Rejected",
  shortlisted: "Shortlisted"
}

export const MISCELLANEOUS = {
  send_remider_mail: "Send Reminder Email",
  add_comment: "Add Comment"
}

export const ADMIN_ROLE = {
  ADMIN: "admin",
  HR_ADMIN: "hr_admin",
  MARKETING_ADMIN: "marketing_admin"
}

export const ALL_PROPERTY_TYPE = {
  PG: "PG/Hostel",
  "office": "Office",
  "flat/apartment": "Apartment",
  "residential_plot": 'Residential Plot/Land',
  "commercial_plot": 'Commercial Plot/Land',
  "projects": "Projects",
  "independent_house": "Independent House",
  "vila/farm_house": "Independent Villa",
  "floor": "Floor",
  "1_rk_studio": "One Rk Studio",
  "residential_others": "Residential Others",
  "retail_shop": "Retail Shop",
  "showroom": "Showroom",
  "warehouse": "warehouse",
  "commercial_other": "Commercial Others",
  "need_flat": "Need Room/Flat",
  "need_flatmate": "Need Roommate"
}

export const ALL_PROPERTY_TYPE_TAGS = {
  PG: "PG",
  office: "commercial_property",
  "flat/apartment": "residential_property",
  "residential_plot": "plot",
  "commercial_plot": 'plot',
  "projects": "projects",
  "independent_house": "residential_property",
  "vila/farm_house": "residential_property",
  "floor": "residential_property",
  "1_rk_studio": "residential_property",
  "residential_others": "residential_property",
  "retail_shop": "commercial_property",
  "showroom": "commercial_property",
  "warehouse": "commercial_property",
  "commercial_other": "commercial_property",
  "need_flat": "share_flat",
  "need_flatmate": "share_flat"
};

export const PROPERTY_TYPE_TAGS = {
  PG: "PG",
  office: "office",
  "flat/apartment": "flat/apartment",
  "residential_plot": "residential",
  "commercial_plot": 'commercial',
  "independent_house": "independent_house",
  "vila/farm_house": "vila/farm_house",
  "floor": "floor",
  "1_rk_studio": "1_rk_studio",
  "residential_others": "others",
  "retail_shop": "retail_shop",
  "showroom": "showroom",
  "warehouse": "warehouse",
  "commercial_other": "other",
  "need_flat": "need_flat",
  "need_flatmate": "need_flatmate"
};

export const GENDER = {
  male: "Male",
  female: "Female",
  all: "Both",
};

export const GENDER_TYPE = {
  MALE: "m",
  FEMALE: "f",
  OTHER: "o",
};

export const GENDER_TYPE_TAGS = {
  m: "Male",
  f: "Female",
  o: "Other"
};

export const PG_FOR_TAGS = {
  MALE: "male",
  FEMALE: "female",
  ALL: "all",
};

export const PROFESSION_OPTIONS = [
  { id: "working", name: "Working" },
  { id: "students", name: "Students" },
  { id: "business", name: "Business" },
  { id: "other", name: "Other" },
];

export  const OTHER_ROOM_OPTIONS  = [
  { value: 'attached_bathroom', label: 'Attached Bathroom' },
  { value: 'attached_balcony', label: 'Attached Balcony' },
];

export const ROOM_TYPE = {
  "private_room": "private",
  "twin_sharing": "twin",
  "triple_sharing": "triple",
  "quad_sharing": "quad"
};

export const ROOM_TYPE_OPTIONS = [
  { id: "private_room", name: "Private" },
  { id: "twin_sharing", name: "Twin" },
  { id: "triple_sharing", name: "Triple" },
  { id: "quad_sharing", name: "Quad" },
];

export const ROOM_TYPE_TAGS = {
  PRIVATE_ROOM: "private_room",
  TWIN_SHARING: "twin_sharing",
  TRIPLE_SHARING: "triple_sharing",
  QUAD_SHARING: "quad_sharing",
};

export const FURNISH_TYPE = {
  fully_furnished: "FULLY FURNISHED",
  semi_furnished: "SEMI FURNISHED",
  unfurnished: "UNFURNISHED"
};

export const FURNISH_TYPE_TAGS = {
  FULLY_FURNISHED: "fully_furnished",
  SEMI_FURNISHED: "semi_furnished",
  UNFURNISHED: "unfurnished",
};

export const INDOOR_AMENITIES = {
  fans: "FANS",
  ac: "AC",
  tv: "TV",
  beds: "BEDS",
  wardrobe: "WARDROBE",
  geysers: "GEYSERS",
  sofa: "SOFA",
  washing_machine: "WASHING_MACHINE",
  stove: "STOVE",
  fridge: "FRIDGE",
  water_purifier: "WATER_PURIFIER",
  microwave: "MICROWAVE",
  modular_kitchen: "MODULAR_KITCHEN",
  chimney: "CHIMNEY",
  dining_table: "DINING_TABLE",
  curtains: "CURTAINS",
  exhaust_fan: "EXHAUST_FAN",
  lights: "LIGHTS",
};

export const INDOOR_AMENITIES_TAGS = {
  FANS: "fans",
  AC: "ac",
  TV: "tv",
  BEDS: "beds",
  WARDROBE: "wardrobe",
  GEYSERS: "geysers",
  SOFA: "sofa",
  WASHING_MACHINE: "washing_machine",
  STOVE: "stove",
  FRIDGE: "fridge",
  WATER_PURIFIER: "water_purifier",
  MICROWAVE: "microwave",
  MODULAR_KITCHEN: "modular_kitchen",
  CHIMNEY: "chimney",
  DINING_TABLE: "dining_table",
  CURTAINS: "curtains",
  EXHAUST_FAN: "exhaust_fan",
  LIGHTS: "lights",
};

export const PROPERTY_MANAGED_BY = {
  landlord: "LANDLORD",
  caretaker: "CARETAKER",
  dedicated_professional: "DEDICATED PROFESSIONAL",
};

export const PROPERTY_MANAGED_BY_TAGS = {
  LANDLORD: "landlord",
  CARETAKER: "caretaker",
  DEDICATED_PROFESSIONAL: "dedicated_professional",
};

export const MANAGER_STAYS_AT_PG = [
  {value: "yes", label: "Manager Stays at Pg"},
];

export const IS_HOT_DEAL = [
  {value: "true", label: "Is it a Hot Deal?"},
];

export const ALLOW_BROKERS = [
  {value: "true", label: "Allow brokers to reach out"},
];

export const trade_license_registration = [
  {value: "true", label: "trade_license_registration"},
];

export const private_parking_basement = [
  {value: "true", label: "Private Parking Basement"},
];

export const private_parking_outside = [
  {value: "true", label: "Private Parking Outside"},
];

export const public_parking = [
  {value: "true", label: "Public Parking"},
];

export const bathrooms_available = [
  {value: "true", label: "Bathroom Available"},
];

export const furnishing = [
  {value: "true", label: "Furnishing"},
];

export const ups = [
  {value: "true", label: "UPS"},
];

export const ups_and_dg_price_included = [
  {value: "true", label: "UPS and DG price included"},
];

export const conference_room_available = [
  {value: "true", label: "Conference Room Available"},
];

export const reception_area = [
  {value: "true", label: "Reception Area"},
];

export const central_ac = [
  {value: "true", label: "Central Ac"},
];

export const oxygen_duct = [
  {value: "true", label: "Oxygen Duct"},
];

export const boundary_wall = [
  {value: "true", label: "With Boundary Wall"},
];

export const occupancy_certificate = [
  {value: "true", label: "Occupancy Certificate"},
];

export const office_noc_certified = [
  {value: "true", label: "Is your Office NOC Certified?"},
];

export const CORNER_PLOT = [
  {value: "true", label: "Is it Corner Plot"},
];

export const FINANCING_AVAILABLE = [
  {value: "true", label: "Is Financing available?"},
];

export  const SECURITY_DEPOSIT_TYPE  = [
  { value: 'fixed', label: 'Fixed' },
  { value: 'multiple_of_rents', label: 'Multiple of rents' },
  { value: 'none', label: 'None' }
];

export const MORE_DETAILS = [
  {value: true, label: "Yes"},
  {value: false, label: "No"}
]

export const is_price_negotiable = [
  {value: "true", label: "Is Price negotiable?"},
]

export const is_electricity_and_water_charge = [
  {value: "true", label: "Is electricity and water charge"},
]

export const tax_and_govt_charges_excluded = [
  {value: "true", label: "Tax and govt charges excluded"},
]

export const INCLUDED_SERVICES = [
  { id: "water_charges", name: "WATER CHARGES" },
  { id: "laundry", name: "LAUNDRY" },
  { id: "wifi", name: "WIFI" },
  { id: "house_keeping", name: "HOUSE KEEPING" },
  { id: "maintenance", name: "MAINTENANCE" },
  { id: "cooking_gas", name: "COOKING GAS" },
  { id: "trash_removal", name: "TRASH REMOVAL" },
  { id: "dth", name: "DTH" },
  { id: "none_of_the_above", name: "NONE OF THE ABOVE" },
];

export const INCLUDED_SERVICE_TAGS = {
  WATER_CHARGES: "water_charges",
  LAUNDRY: "laundry",
  WIFI: "wifi",
  HOUSE_KEEPING: "house_keeping",
  MAINTENANCE: "maintenance",
  COOKING_GAS: "cooking_gas",
  TRASH_REMOVAL: "trash_removal",
  DTH: "dth",
  NONE_OF_THE_ABOVE: "none_of_the_above",
};

export const MEALS_AVAILABLE = {
  yes: "YES",
  no: "NO",
  need_to_pay_extra: "NEED TO PAY EXTRA",
};

export const MEALS_AVAILABLE_TAGS = {
  YES: "yes",
  NO: "no",
  NEED_TO_PAY_EXTRA: "need_to_pay_extra",
};

export const MEALS_TYPE = {
  veg: "Only Veg",
  non_veg: "Veg & Non Veg",
};

export const MEALS_TYPE_TAGS = {
  VEG: "veg",
  NON_VEG: "non_veg",
};

export const MEALS = [
  { id: "breakfast", name: "BREAKFAST" },
  { id: "lunch", name: "LUNCH" },
  { id: "dinner", name: "DINNER" },
];

export const MEALS_TAGS = {
  BREAKFAST: "breakfast",
  LUNCH: "lunch",
  DINNER: "dinner",
};

export const NOTICE_PERIOD = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
};

export const LEASE_YEARS = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  '5+': '5+',
}; 

export const LOCK_IN_PERIOD_TYPE = {
  NONE: "none",
  CUSTOM: "custom",
};

export const LOCK_IN_PERIOD = {
  none: "None",
  custom: "Custom"
};

export const LOCK_IN_PERIOD_TAGS = {
  NONE: "none",
  ONE_MONTH: "1_month",
  TWO_MONTH: "2_month",
  SIX_MONTH: "6_month",
  CUSTOM: "custom",
};

export const PG_RULES = [
  { id: "veg", name: "VEG" },
  { id: "gender_restrictions", name: "GENDER RESTRICTIONS" },
  { id: "no_smoke", name: "NO SMOKE" },
  { id: "no_alcohol", name: "NO ALCOHOL" },
  { id: "no_curfew", name: "NO CURFEW" },
  { id: "guests_allowed", name: "GUESTS ALLOWED" },
  { id: "parties_events_allowed", name: "PARTIES EVENTS ALLOWED" },
  { id: "guardians_allowed", name: "GUARDIANS ALLOWED" },
];

export const AMENITIES = [
  { id: "lift", name: "LIFT" },
  { id: "power_backup", name: "POWER BACKUP" },
  { id: "gym", name: "GYM" },
  { id: "swimming_pool", name: "SWIMMING POOL" },
  { id: "cctv", name: "CCTV" },
  { id: "gated_community", name: "GATED COMMUNITY" },
  { id: "water_supply", name: "WATER SUPPLY" },
  { id: "parking", name: "PARKING" },
  { id: "kids_area", name: "KIDS AREA" },
  { id: "playground", name: "PLAYGROUND" },
  { id: "community_garden", name: "COMMUNITY GARDEN" },
  { id: "wifi", name: "WIFI" },
  { id: "club", name: "CLUB" },
  { id: "gas", name: "GAS" },
  { id: "sewage", name: "SEWAGE" },
  { id: "fire_alarm", name: "FIRE ALARM" },
  { id: "house_keeper", name: "HOUSE KEEPER" },
  { id: "park", name: "PARK" },
  { id: "shopping_center", name: "SHOPPING CENTER" },
  { id: "intercom", name: "INTERCOM" },
];

export const GATE_TIMING = {
  "7_pm": "7 PM",
  "7_30_pm": "7:30 PM",
  "8_pm": "8 PM",
  "8_30_pm": "8:30 PM",
  "9_pm": "9 PM",
  "9_30_pm": "9:30 PM",
  "10_pm": "10 PM",
  "10_30_pm": "10:30 PM",
  "11_pm": "11 PM",
  "11_30_pm": "11:30 PM",
  "12_am": "12 AM",
};

export const GATE_TIMING_TAGS = {
  SEVEN_PM: "7_pm",
  SEVEN_THIRTY_PM: "7_30_pm",
  EIGHT_PM: "8_pm",
  EIGHT_THIRTY_PM: "8_30_pm",
  NINE_PM: "9_pm",
  NINE_THIRTY_PM: "9_30_pm",
  TEN_PM: "10_pm",
  TEN_THIRTY_PM: "10_30_pm",
  ELEVEN_PM: "11_pm",
  ELEVEN_THIRTY_PM: "11_30_pm",
  TWELVE_AM: "12_am",
};

export const COMMON_AREAS = [
  { id: "living_room", name: "LIVING ROOM" },
  { id: "kitchen", name: "KITCHEN" },
  { id: "dining_hall", name: "DINING HALL" },
  { id: "pooja_room", name: "POOJA ROOM" },
  { id: "study_room", name: "STUDY ROOM" },
];

export const COMMON_AREAS_TAGS = {
  LIVING_ROOM: "living_room",
  KITCHEN: "kitchen",
  DINING_HALL: "dining_hall",
  POOJA_ROOM: "pooja_room",
  STUDY_ROOM: "study_room",
};
 
export const CONSTRUCTION_STATUS = [
  {value: "ready_to_move", label: "Ready to move"},
  {value: "under_construction", label: "Under Construction"}
];

export const any_construction_done = [
  {value: "yes", label: "Yes"},
  {value: "no", label: "No"}
];

export const LOCATION_HUB = {
  "shopping_malls": "SHOPPING MALLS",
  "commercial_project": "COMMERCIAL PROJECT",
  "market/high_street": "MARKET HIGH STREET",
  "retail_complex/building": "RETAIL COMPLEX BUILDING",
  "industrial_areas": "INDUSTRIAL AREAS",
  "healthcare_center": "HEALTHCARE CENTER",
  "commercial_streets": "COMMERCIAL STREETS",
  "downtown_or_city_center": "DOWNTOWN OR CITY CENTER",
  "tourist_areas": "TOURIST AREAS",
  "transport_hubs": "TRANSPORT HUBS",
  "business_districts": "BUSINESS DISTRICTS",
  "entertainment_complexes": "ENTERTAINMENT COMPLEXES",
  "other": "OTHER",
};

export const LOCATION_HUB_TAGS = {
  SHOPPING_MALLS: "shopping_malls",
  COMMERCIAL_PROJECT: "commercial_project",
  MARKET_HIGH_STREET: "market/high_street",
  RETAIL_COMPLEX_BUILDING: "retail_complex/building",
  INDUSTRIAL_AREAS: "industrial_areas",
  HEALTHCARE_CENTER: "healthcare_center",
  COMMERCIAL_STREETS: "commercial_streets",
  DOWNTOWN_OR_CITY_CENTER: "downtown_or_city_center",
  TOURIST_AREAS: "tourist_areas",
  TRANSPORT_HUBS: "transport_hubs",
  BUSINESS_DISTRICTS: "business_districts",
  ENTERTAINMENT_COMPLEXES: "entertainment_complexes",
  OTHER: "other",
};

export const POSSESSION_PERIOD = {
  within_15_days: "Within 15 Days",
  within_1_month: "Within 1 Month",
  within_2_months: "Within 2 Months",
  within_3_months: "Within 3 Months",
  within_6_months: "Within 6 Months",
  by_2024: "By 2024",
  by_2025: "By 2025",
  by_2026: "By 2026",
  by_2027: "By 2027",
  by_2028: "By 2028",
  by_2029: "By 2029",
  by_2030: "By 2030",
};

export const AGE_OF_PROPERTY = {
  "less_than_1_year": "Less Than 1 Year",
  "1_to_3_years": "1 To 3 Years",
  "3_to_5_years": "3 To 5 Years",
  "5_to_10_years": "5 To 10 Years",
  "more_than_10_years": "More Than 10 Years",
};

export const BHK = {
  '1BHK': '1 BHK',
  '2BHK': '2 BHK',
  '3BHK': '3 BHK',
  '4BHK': '4 BHK',
  '5BHK': '5 BHK',
  '5+BHK': '5+ BHK',
};

export const BATHROOMS = {
  "1": "1 Bathroom",
  "2": "2 Bathrooms",
  "3": "3 Bathrooms",
  "4": "4 Bathrooms",
  "5": "5 Bathrooms",
  "5+": "5+ Bathrooms"
};

export const BALCONIES = {
  "1": "1 Balcony",
  "2": "2 Balconies",
  "3": "3 Balconies",
  "3+": "3+ Balconies",
};

export const ADDITIONAL_ROOMS = [
  { id: "pooja_room", name: "POOJA ROOM" },
  { id: "home_gym", name: "HOME GYM" },
  { id: "study_room", name: "STUDY ROOM" },
  { id: "servant_room", name: "SERVANT ROOM" },
  { id: "home_office", name: "HOME OFFICE" },
  { id: "game_room", name: "GAME ROOM" },
  { id: "others", name: "OTHERS" }
];

export const FACING = {
  "north": "NORTH",
  "south": "SOUTH",
  "east": "EAST",
  "west": "WEST",
  "north-east": "NORTH-EAST",
  "north-west": "NORTH-WEST",
  "south-east": "SOUTH-EAST",
  "south-west": "SOUTH-WEST",
};

export const FLOORING = {
  "granite_tiles": "GRANITE TILES",
  "concrete": "CONCRETE",
  "vitrified_tiles": "VITRIFIED TILES",
  "ceramic_tiles": "CERAMIC TILES",
  "polished_concrete": "POLISHED CONCRETE",
  "mosaic_stone": "MOSAIC STONE",
  "spartex_tiles": "SPARTEX TILES",
  "wood": "WOOD",
  "ips_finish": "IPS FINISH",
  "vinyl_sheet_tiles": "VINYL SHEET TILES",
  "stone": "STONE",
  "others": "OTHERS"
}

export const AREA_UNIT = {
  "square_feet": "Square Feet (Sq ft)",
  "square_meters": "Square Meter (Sq m)",
  "square_yards": "Square Yard (Sq yd)",
  "guz": "Guz (Sq guz)"
};

export const PER_AREA_UNIT = {
  "square_feet": "ft.",
  "square_meters": "mt.",
  "square_yards": "yd.",
  "guz": "guz"
};

export const TENANT_TYPE = [
  { id: "family", name: "FAMILY" },
  { id: "male", name: "MALE" },
  { id: "female", name: "FEMALE" },
  { id: "other", name: "OTHER" }
]

export  const RENT_TYPE  = [
  { value: "only_rent", label: 'Only Rent' },
  { value: "only_lease", label: 'Only Lease' }
];

export const PREFERENCES = [
  { id: "bachelor", name: "BACHELOR" },
  { id: "family", name: "FAMILY" },
  { id: "living_couple", name: "LIVING COUPLE" },
  { id: "professional", name: "PROFESSIONAL" },
  { id: "student", name: "STUDENT" },
  { id: "no_pets", name: "NO PETS" },
  { id: "no_smoking", name: "NO SMOKING" },
  { id: "no_alcohol", name: "NO ALCOHOL" },
  { id: "gender_restriction", name: "GENDER RESTRICTION" },
  { id: "guests_allowed", name: "GUESTS ALLOWED" },
  { id: "veg", name: "VEG" },
];
export const PROPERTY_COLLECTIONS = {
  PLOT: "plot",
  RESIDENTIAL_PROPERTY: "residential_property",
  COMMERCIAL_PROPERTY: "commercial_property",
  PG: "PG",
  SHARE_FLAT: "share_flat",
};

export const AUTO_TITLE_DESC_PROPERTY_TYPE_TAGS = {
  PG: "PG",
  "residential_property": "residential",
  "plot": "plot",
  "commercial_property": "commercial",
  need_flat : "need_flat",
  need_flatmate: "need_flatmate"
}

export const AUTO_TITLE_DESC_PROPERTY_TYPE = {
  PLOT: "plot",
  RESIDENTIAL: "residential",
  COMMERCIAL: "commercial",
  PG: "PG",
  NEED_FLAT: "need_flat",
  NEED_FLATMATE: "need_flatmate",
  "flat/apartment": "residential",
  "residential_property" : "residential"
};

export const RESIDENTIAL_PROPERTY_TYPE = {
  FLAT_APARTMENT: "flat/apartment",
  FLOOR: "floor",
  INDEPENDENT_HOUSE: "independent_house",
  VILA_FARM_HOUSE: "vila/farm_house",
  ONE_RK_STUDIO: "1_rk_studio",
  OTHERS: "others",
};

export const RERA_STATUS = {
  REGISTERED: "registered",
  UNDER_REGISTRATION: "under_registration",
  EXPIRED: "expired",
  COMPLETED: "completed"
};

export const RERA_STATUS_TAGS = {
  "registered": "REGISTERED",
  "under_registration": "UNDER_REGISTRATION",
  "expired": "EXPIRED",
  "completed": "COMPLETED"
};

export const RESIDENTIAL_PROPERTY_ARRAY = [
  { id: 'flat/apartment', name: 'Flat Apartment' },
  { id: 'floor', name: 'Floor' },
  { id: 'independent_house', name: 'Independent House' },
  { id: 'vila/farm_house', name: 'Vila Farm House' },
  { id: '1_rk_studio', name: '1 RK Studio' },
  { id: 'others', name: 'Others' }
];

export const COMMERCIAL_PROPERTY_ARRAY = [
  { id: 'office', name: 'Office' },
  { id: 'warehouse', name: 'Warehouse' },
  { id: 'retail_shop', name: 'Retail Shop' },
  { id: 'showroom', name: 'Showroom' },
  { id: 'plot', name: 'Plot' }
];

export const PLOT_PROPERTY_TAGS_ARRAY = [
  { id: 'residential_property', name: 'Residential Plot' },
  { id: 'commercial_property', name: 'Commercial Plot' }
];


export const BHK_TAGS = {
  ONE_RK: "1RK",
  ONE_BHK: "1BHK",
  TWO_BHK: "2BHK",
  THREE_BHK: "3BHK",
  FOUR_BHK: "4BHK",
  FIVE_BHK: "5BHK",
  FIVE_PLUS_BHK: "5+BHK",
};

export const TENANT_TYPE_TAGS = {
  FAMILY: "family",
  MALE: "male",
  FEMALE: "female",
  OTHER: "other",
};

export const FACING_TAGS = {
  NORTH: "north",
  SOUTH: "south",
  EAST: "east",
  WEST: "west",
  NORTH_EAST: "north-east",
  NORTH_WEST: "north-west",
  SOUTH_EAST: "south-east",
  SOUTH_WEST: "south-west",
};

export const CONSTRUCTION_STATUS_TAGS = {
  READY_TO_MOVE: "ready_to_move",
  UNDER_CONSTRUCTION: 'under_construction',
};

export const AGE_OF_PROPERTY_TAGS = {
  NEW: "new",
  LESS_THAN_1_YEAR: "less_than_1_year",
  ONE_TO_3_YEARS: "1_to_3_years",
  THREE_TO_5_YEARS: "3_to_5_years",
  FIVE_TO_10_YEARS: "5_to_10_years",
  MORE_THAN_10_YEARS: "more_than_10_years",
};
 
export const SECURITY_DEPOSIT_TYPE_TAGS = {
  NONE: "none",
  FIXED: "fixed",
  MULTIPLE_OF_RENTS: "multiple_of_rents",
};

export const LEASE_YEARS_TAGS = {
  ONE: "1",
  TWO: "2",
  THREE: "3",
  FOUR: "4",
  FIVE: "5",
  FIVE_PLUS: "5+",
};

export const BATHROOMS_TAGS = {
  Shared: "shared",
  ONE: "1",
  TWO: "2",
  THREE: "3",
  FOUR: "4",
  FOUR_PLUS: "4+",
  FIVE: "5",
  FIVE_PLUS: "5+",
};

export const BALCONIES_TAGS = {
  ONE: "1",
  TWO: "2",
  THREE: "3",
  THREE_PLUS: "3+",
};

export const OWNERSHIP = {
  FREEHOLD: "freehold",
  LEASEHOLD: "leasehold",
  COOPERATIVE_SOCIETY: "cooperative_society",
  POWER_OF_ATTORNEY: "power_of_attorney",
};

export const OWNERSHIP_TAGS = {
  "freehold": "FREEHOLD",
  "leasehold": "LEASEHOLD",
  "cooperative_society": "COOPERATIVE SOCIETY",
  "power_of_attorney": "POWER OF ATTORNEY"
};

export const DISCOUNT_TYPE = {
  PERCENTAGE: "percentage",
  AMOUNT: "amount",
};

export const DISCOUNT_TYPE_TAGS = {
  "percentage": 'Percentage',
  "amount": "Amount",
};

export const ADDITIONAL_ROOMS_TAGS = {
  POOJA_ROOM: "pooja_room",
  HOME_GYM: "home_gym",
  STUDY_ROOM: "study_room",
  SERVANT_ROOM: "servant_room",
  HOME_OFFICE: "home_office",
  GAME_ROOM: "game_room",
  OTHERS: "others",
};

export const FLOORING_TAGS = {
  GRANITE_TILES: "granite_tiles",
  CONCRETE: "concrete",
  VITRIFIED_TILES: "vitrified_tiles",
  CERAMIC_TILES: "ceramic_tiles",
  POLISHED_CONCRETE: "polished_concrete",
  MOSAIC_STONE: "mosaic_stone",
  SPARTEX_TILES: "spartex_tiles",
  WOOD: "wood",
  IPS_FINISH: "ips_finish",
  VINYL_SHEET_TILES: "vinyl_sheet_tiles",
  STONE: "stone",
  OTHERS: "others",
};

export const POSSESSION_PERIOD_TAGS = {
  WITHIN_15_DAYS: "within_15_days",
  WITHIN_1_MONTH: "within_1_month",
  WITHIN_2_MONTHS: "within_2_months",
  WITHIN_3_MONTHS: "within_3_months",
  WITHIN_6_MONTHS: "within_6_months",
  BY_2024: "by_2024",
  BY_2025: "by_2025",
  BY_2026: "by_2026",
  BY_2027: "by_2027",
  BY_2028: "by_2028",
  BY_2029: "by_2029",
  BY_2030: "by_2030",
};

export const MONTHS = {
  JANUARY: "january",
  FEBRUARY: "february",
  MARCH: "march",
  APRIL: "april",
  MAY: "may",
  JUNE: "june",
  JULY: "july",
  AUGUST: "august",
  SEPTEMBER: "september",
  OCTOBER: "october",
  NOVEMBER: "november",
  DECEMBER: "december",
};

export const MONTHS_TAGS = {
  "january": 'JANUARY',
  "february": 'FEBRUARY',
  "march": 'MARCH',
  "april": 'APRIL',
  "may": 'MAY',
  "june": 'JUNE',
  "july": 'JULY',
  "august": 'AUGUST',
  "september": 'SEPTEMBER',
  "october": 'OCTOBER',
  "november": 'NOVEMBER',
  "december": 'DECEMBER',
};

export const AREA_UNIT_TAGS = {
  SQUARE_FEET: "square_feet",
  SQUARE_METERS: "square_meters",
  // Residential Sale URLs
  ACRES: "acres",
  SQUARE_YARDS: "square_yards",
  GUZ: "guz",
};

export const AMENITIES_TAGS = {
  LIFT: "lift",
  POWER_BACKUP: "power_backup",
  GYM: "gym",
  SWIMMING_POOL: "swimming_pool",
  CCTV: "cctv",
  GATED_COMMUNITY: "gated_community",
  WATER_SUPPLY: "water_supply",
  PARKING: "parking",
  KIDS_AREA: "kids_area",
  PLAYGROUND: "playground",
  COMMUNITY_GARDEN: "community_garden",
  WIFI: "wifi",
  CLUB: "club",
  GAS: "gas",
  SEWAGE: "sewage",
  FIRE_ALARM: "fire_alarm",
  HOUSE_KEEPER: "house_keeper",
  PARK: "park",
  SHOPPING_CENTER: "shopping_center",
  INTERCOM: "intercom",
};

export const PREFERENCES_TAGS = {
  BACHELOR: "bachelor",
  FAMILY: "family",
  LIVING_COUPLE: "living_couple",
  PROFESSIONAL: "professional",
  STUDENT: "student",
  NO_PETS: "no_pets",
  NO_SMOKING: "no_smoking",
  NO_ALCOHOL: "no_alcohol",
  GENDER_RESTRICTION: "gender_restriction",
  GUESTS_ALLOWED: "guests_allowed",
  VEG: "veg",
};

export const PLOT_PROPERTY_TYPE = {
  RESIDENTIAL: "residential",
  COMMERCIAL: "commercial",
};

export const PLOT_PROPERTY_TYPE_TAGS = {
  "residential": 'RESIDENTIAL',
  "commercial": 'COMMERCIAL',
};

export const PLOT_PROJECT_TAGS_ARRAY = [
  { id: 'residential', name: 'Residential' },
  { id: 'commercial', name: 'Commercial' }
];

export const ZONE_TYPE = {
  INDUSTRIAL_COMPLEX: "industrial_complex",
  COMMERCIAL_COMPLEX: "commercial_complex",
  RESIDENTIAL_COMPLEX: "residential_complex",
  AGRICULTURE_ZONE: "agriculture_zone",
  SPECIAL_ECONOMIC_ZONE: "special_economic_zone",
  NATURAL_CONSERVATION_ZONE: "natural_conservation_zone",
  TRANSPORT_AND_COMMUNICATION: "transport_and_communication",
  PUBLIC_UTILITIES: "public_utilities",
  PUBLIC_AND_SEMI_PUBLIC_USE: "public_and_semi_public_use",
  OPEN_SPACE: "open_space",
  IT_PARK: "it_park",
  BUSINESS_PARK: "business_park",
  CORPORATE_CAMPUS: "corporate_campus",
  GOVERNMENT_USE: "government_use",
  OTHER: "other",
};

export const ZONE_TYPE_TAGS = {
  "industrial_complex": "INDUSTRIAL COMPLEX",
  "commercial_complex": "COMMERCIAL COMPLEX",
  "residential_complex": "RESIDENTIAL COMPLEX",
  "agriculture_zone": "AGRICULTURE ZONE",
  "special_economic_zone": "SPECIAL ECONOMIC ZONE",
  "natural_conservation_zone": "NATURAL CONSERVATION ZONE",
  "transport_and_communication": "TRANSPORT AND COMMUNICATION",
  "public_utilities": "PUBLIC UTILITIES",
  "public_and_semi_public_use": "PUBLIC AND SEMI PUBLIC USE",
  "open_space": "OPEN SPACE",
  "it_park": "IT PARK",
  "business_park": "BUSINESS PARK",
  "corporate_campus": "CORPORATE CAMPUS",
  "government_use": "GOVERNMENT USE",
  "other": "OTHER",
};

export const CONSTRUCTION_TYPE_OF_PLOT = {
  SHED: "shed",
  ROOM: "room",
  WASHROOM: "washroom",
  OTHER: "other",
};

export const CONSTRUCTION_TYPE_OF_PLOT_TAGS = {
  "shed": "SHED",
  "room": "ROOM",
  "washroom": "WASHROOM",
  "other": "OTHER",
};

export const NO_OF_OPEN_SIDES = {
  ONE: "1",
  TWO: "2",
  THREE: "3",
  THREE_PLUS: "3+",
};

export const NO_OF_OPEN_SIDES_TAGS = {
  "1": "1",
  "2": "2",
  "3": "3",
  "3+": "3+"
};

export const PLOT_PROPERTY_APPROVED_BY = {
  YEIDA: "YEIDA",
  UPAVP: "UPAVP",
  NOIDA: "NOIDA",
};

export const DIMENSION_UNIT = {
  FEET: "feet",
  METERS: "meters",
  YARDS: "yards",
  GUZ: "guz",
};

export const DIMENSION = {
  "feet": "Feet (ft.)",
  "meters": "Meters (mt.)"
};

export const DIMENSION_UNIT_TAGS = {
  "square_feet": "feet",
  "square_meters": "meters",
  "square_yards": "yards",
  "guz": "guz",
};

export const PG_AMENITIES = {
  FENCING_WALL: "fencing_wall",
  CCTV_SURVEILLANCE: "cctv_surveillance",
  SECURITY_GATE: "security_gate",
  GUARDS: "guards",
  COMMUNITY_HALL: "community_hall",
  PARKING: "parking",
  STORAGE_SHEDS: "storage_sheds",
  GARDEN: "garden",
  INTERNAL_ROAD: "internal_road",
  GATED_COMMUNITY: "gated_community",
  CHILDREN_PLAY_AREA: "children_play_area",
  ATM: "atm",
  UTILITY_SHOPS: "utility_shops",
  GYM: "gym",
  SQUASH_COURT: "squash_court",
  TENNIS_COURT: "tennis_court",
  FOOTBALL_FIELD: "football_field",
  BASKETBALL_COURT: "basketball_court",
  BADMINTON_COURT: "badminton_court",
  VOLLEYBALL_COURT: "volleyball_court",
};

export const PG_AMENITIES_LIST = [
  { id: "fencing_wall", name: "Fencing Wall" },
  { id: "cctv_surveillance", name: "CCTV Surveillance" },
  { id: "security_gate", name: "Security Gate" },
  { id: "guards", name: "Guards" },
  { id: "community_hall", name: "Community Hall" },
  { id: "parking", name: "Parking" },
  { id: "storage_sheds", name: "Storage Sheds" },
  { id: "garden", name: "Garden" },
  { id: "internal_road", name: "Internal Road" },
  { id: "gated_community", name: "Gated Community" },
  { id: "children_play_area", name: "Children Play Area" },
  { id: "atm", name: "ATM" },
  { id: "utility_shops", name: "Utility Shops" },
  { id: "gym", name: "Gym" },
  { id: "squash_court", name: "Squash Court" },
  { id: "tennis_court", name: "Tennis Court" },
  { id: "football_field", name: "Football Field" },
  { id: "basketball_court", name: "Basketball Court" },
  { id: "badminton_court", name: "Badminton Court" },
  { id: "volleyball_court", name: "Volleyball Court" },
];

export const PROPERTY_TABLE_TAG = {
  "flat/apartment": "Flats",
  "floor": "Builder Floor",
  "independent_house": "Independent House",
  "1_rk_studio": "Studio",
  "pg": "PG",
  "office": "Office",
  "residential-plots-land": "Residential Plot",
  "commercial-plots-land": "Commercial Plot",
  "warehouse": "Warehouse",
  "retail_shop": "Shops",
  "vila/farm_house": "Villa/Farm House",
  "showroom": "Showroom",
  "share_flat": "Shared Flats"
}

export const SLUG_URL_PROPERTY_TAG = {
  "floor": "builder-floors",
  "flat/apartment": "flats",
  "office": "office-spaces",
  "warehouse": "warehouses",
  "retail_shop": "shops",
  "independent_house": "independent-house",
  "showroom": "showrooms",
  "1_rk_studio": "studios",
  "vila/farm_house": "villas",
}
export const COMMERCIAL_PROPERTY_TYPE = {
  OFFICE: "office",
  WAREHOUSE: "warehouse",
  RETAIL_SHOP: "retail_shop",
  SHOWROOM: "showroom",
  PLOT: "plot",
  OTHER: "other",
};

export const COMMERCIAL_SUITABLE_FOR = {
  JEWELLERY_STORE: "jewellery_store",
  GYM: "gym",
  MEDICAL_CLINIC: "medical/clinic",
  FOOTWEAR_SHOP: "footwear_shop",
  CLOTHING_STORE: "clothing_store",
  SUPERMARKET_GROCERY_STORE: "supermarket/grocery_store",
  ELECTRONICS_STORE: "electronics_store",
  HOME_FURNISHINGS_STORE: "home_furnishings_store",
  BEAUTY_AND_COSMETICS_STORE: "beauty_and_cosmetics_store",
  TOY_STORE: "toy_store",
  BOOKSTORE: "bookstore",
  STATIONERY_STORE: "stationery_store",
  PET_STORE: "pet_store",
  GENERAL_STORE: "general_store",
  OTHER: "other",
};

export const PROPERTY_CONDITION = {
  READY_TO_USE: "ready_to_use",
  BARE_SHELL: "bare_shell",
};

export const PROPERTY_CONDITION_TAGS = [
  { value: "ready_to_use", label: "Ready to Use" },
  { value: "bare_shell", label: "Bare Shell" },
];

export const PARKING_TYPE = {
  AVAILABLE: "available",
  NOT_AVAILABLE: "not_available",
};

export const PARKING_TYPE_TAGS = [
  {value: "available", label: "Available"},
  {value: "not_available", label: "Not Available"}
];

export const PANTRY = {
  PRIVATE: "private",
  SHARED: "shared",
};

export const PANTRY_AVAILABLE = [
  {value: "true", label: "Pantry Available"},
];

export const PANTRY_TAGS = [
  {value: "private", label: "Private"},
  {value: "shared", label: "Shared"}
];

export const CONSTRUCTION_STATUS_OF_WALL = {
  NO_WALLS: "no_walls",
  BRICK_WALLS: "brick_walls",
  CEMENTED: "cemented_walls",
  PLASTERED: "plastered_walls",
  OTHER: "other",
};

export const CONSTRUCTION_STATUS_OF_WALL_TAGS = {
  no_walls: "NO WALLS",
  brick_walls: "BRICK WALLS",
  cemented_walls: "CEMENTED WALLS",
  plastered_walls: "PLASTERED WALLS",
  other: "OTHER",
};


export const COMMERCIAL_AMENITIES = {
  CCTV: "cctv",
  OXYGEN_DUCT: "oxygen_duct",
  CENTRAL_AC: "central_ac",
  INTERNET_CONNECTIVITY: "internet_connectivity",
  FIRE_EXTINGUISHERS: "fire_extinguishers",
  FIRE_SENSORS: "fire_sensors",
  WATER_STORAGE: "water_storage",
  SECURITY_PERSONNEL: "security_personnel",
  DG_AVAILABILITY: "dg_availability",
  CAFETERIA: "cafeteria",
  VASTU_COMPLIANT: "vastu_compliant",
  POWER_BACKUP: "power_backup",
  CLEANING_SERVICES: "cleaning_services",
  LIFT: "lift",
  ACCESS_24_7: "access_24_7",
  PARKING_FACILITIES: "parking_facilities",
};

export const COMMERCIAL_AMENITIES_TAGS = [
  { id: "cctv", name: "CCTV" },
  { id: "oxygen_duct", name: "Oxygen Duct" },
  { id: "central_ac", name: "Central AC" },
  { id: "internet_connectivity", name: "Internet Connectivity" },
  { id: "fire_extinguishers", name: "Fire Extinguishers" },
  { id: "fire_sensors", name: "Fire Sensors" },
  { id: "water_storage", name: "Water Storage" },
  { id: "security_personnel", name: "Security Personnel" },
  { id: "dg_availability", name: "DG Availability" },
  { id: "cafeteria", name: "Cafeteria" },
  { id: "vastu_compliant", name: "Vastu Compliant" },
  { id: "power_backup", name: "Power Backup" },
  { id: "cleaning_services", name: "Cleaning Services" },
  { id: "lift", name: "Lift" },
  { id: "access_24_7", name: "Access 24/7" },
  { id: "parking_facilities", name: "Parking Facilities" },
];

export const FIRE_SAFETY_MEASURES = {
  FIRE_EXTINGUISHER: "fire_extinguisher",
  FIRE_SENSORS: "fire_sensors",
  SPRINKLES: "sprinkles",
  FIREHOSE: "firehose",
};

export const FIRE_SAFETY_MEASURES_TAGS = [
  { id: "fire_extinguisher", name: "FIRE EXTINGUISHER" },
  { id: "fire_sensors", name: "FIRE SENSORS" },
  { id: "sprinkles", name: "SPRINKLES" },
  { id: "firehose", name: "FIREHOSE" },
];

export const COMMERCIAL_PROPERTY_TYPE_TAGS = {
  office: "OFFICE",
  warehouse: "WAREHOUSE",
  retail_shop: "RETAIL_SHOP",
  showroom: "SHOWROOM",
  plot: "PLOT",
  other: "OTHER",
};

export const PROJECT_INFO_STEPS = {
  BASIC_INFORMATION: "basic_information",
  FLOOR_PRICING_INFORMATION: "floor_pricing_information",
  BROCHURE_IMAGE: "brochure_image",
  COMPLETED: "completed"
}

export const PROJECT_INFO_STEPS_TAGS = {
  basic_information: "BASIC INFORMATION",
  floor_pricing_information: "FLOOR PRICING INFORMATION",
  brochure_image: "BROCHURE IMAGE",
  completed: "COMPLETED"
};

export const PROJECT_AREA_UNIT = {
  ACRES: "acres",
  HECTRE: "hectres",
};

export const PROJECT_AREA_UNIT_TAGS = {
  acres: "Acres",
  hectres: "Hectres",
};


export const PROJECT_PROPERTY_TYPE = {
  RESIDENTIAL_PROPERTY: "residential_property",
  COMMERCIAL_PROPERTY: "commercial_property",
  PLOT: "plot",
}

export const PROJECT_PROPERTY_TYPE_TAGS = [
  { id: 'residential_property', name: 'Residential Property' },
  { id: 'commercial_property', name: 'Commercial Property' },
  { id: 'plot', name: 'Plot' }
];

export const PROJECT_PROPERTIES = {
  FLAT_APARTMENT: "flat/apartment",
  FLOOR: "floor",
  INDEPENDENT_HOUSE: "independent_house",
  VILA_FARM_HOUSE: "vila/farm_house",
  ONE_RK_STUDIO: "1_rk_studio",
  OTHERS: "others",
  OFFICE: "office",
  WAREHOUSE: "warehouse",
  RETAIL_SHOP: "retail_shop",
  SHOWROOM: "showroom",
  PLOT: "plot",
  DUPLEX: "duplex",
  RESIDENTIAL: "residential",
  COMMERCIAL: "commercial",
}
 
export const PROJECT_STATUS = {
  READY_TO_MOVE: "ready_to_move",
  UNDER_CONSTRUCTION: "under_construction",
  UPCOMING: "upcoming"
};

export const PROJECT_STATUS_TAGS = {
  "ready_to_move": "Ready to Move",
  "under_construction": "Under Construction",
  "upcoming": "Upcoming"
};


export const PROJECT_AMENITIES = {
  VALET_PARKING: "valet_parking",
  SWIMMING_POOL: "swimming_pool",
  FOOTBALL_GROUND: "football_ground",
  FLOWER_GARDEN: "flower_garden",
  READING_LOUNGE: "reading_lounge",
  REFLEXOLOGY_PARK: "reflexology_park",
  VOLLEY_BALL_COURT: "volley_ball_court",
  CARD_ROOM: "card_room",
  HIGH_SPEED_ELEVATOR: "high_speed_elevator",
  SAUNA: "sauna",
  JACUZZI: "jacuzzi",
  STEAM_ROOM: "steam_room",
  CRECHE_DAY_CARE: "creche_day_care",
  GAZEBO: "gazebo",
  WIFI_CONNECTIVITY: "wifi_connectivity",
  POOL_TABLE: "pool_table",
  PROPERTY_STAFF: "property_staff",
  CAFETERIA: "cafeteria",
  BILLIARDS: "billiards",
  BANQUET_HALL: "banquet_hall",
  FOUNTAIN: "fountain",
  SHOPPING_CENTRE: "shopping_centre",
  SUN_DECK: "sun_deck",
  MULTIPURPOSE_COURT: "multipurpose_court",
  EARTHQUAKE_RESISTANT: "earthquake_resistant",
  TODDLER_POOL: "toddler_pool",
  PARTY_LAWN: "party_lawn",
  TABLE_TENNIS: "table_tennis",
  BASKET_BALL_COURT: "basket_ball_court",
  BADMINTON_COURT: "badminton_court",
  ENTRANCE_LOBBY: "entrance_lobby",
  YOGA_MEDITATION_AREA: "yoga_meditation_area",
  INDOOR_GAMES: "indoor_games",
  CCTV_CAMERA_SECURITY: "cctv_camera_security",
  JOGGING_TRACK: "jogging_track",
  RAIN_WATER_HARVESTING: "rain_water_harvesting",
  SECURITY_24_7: "security_24_7",
  CLUB_HOUSE: "club_house",
  POWER_BACKUP_24_7: "power_backup_24_7",
  GATED_COMMUNITY: "gated_community",
  GYMNASIUM: "gymnasium",
  WATER_SUPPLY_24_7: "water_supply_24_7",
  LANDSCAPE_GARDEN: "landscape_garden",
  CHILDREN_PLAY_AREA: "children_play_area",
  LIFT: "lift",
  CAR_PARKING: "car_parking",
  CARROM: "carrom",
  CHESS: "chess",
  OPEN_SPACE: "open_space",
  SECURITY_CABIN: "security_cabin",
  SENIOR_CITIZEN_SITOUT: "senior_citizen_sitout",
  PAVED_COMPOUND: "paved_compound",
  INTERCOM: "intercom",
  FIRE_FIGHTING_SYSTEMS: "fire_fighting_systems",
  SQUASH_COURT: "squash_court",
  VIDEO_DOOR_SECURITY: "video_door_security",
  AMPITHEATRE: "ampitheatre",
  SEWAGE_TREATMENT_PLANT: "sewage_treatment_plant",
  GOLF_COURSE: "golf_course",
  SCHOOL: "school",
  GARABAGE_DISPOSAL: "garabage_disposal",
  RO_SYSTEM: "ro_system",
  POWER_BACKUP_LIFT: "power_backup_lift",
  MINI_THEATRE: "mini_theatre",
  CONCIERGE_SERVICE: "concierge_service",
  BAR_CHILLOUT_LOUNGE: "bar_chillout_lounge",
  PERGOLA: "pergola",
  PIPED_GAS: "piped_gas",
  SPA: "spa",
  SKATING_RINK: "skating_rink",
  GROCERY_SHOP: "grocery_shop",
  WHEELCHAIR_ACCESIBLE: "wheelchair_accesible",
  INTERNAL_STREETLIGHTS: "internal_streetlights",
  MAINTENANCE_STAFF: "maintenance_staff",
  BARBEQUE: "barbeque",
  VASTU_COMPLIANT: "vastu_compliant",
  DOCTOR_ON_CALL: "doctor_on_call",
  RESTAURANT: "restaurant",
  CRICKET_PITCH: "cricket_pitch",
  DTH_TELEVISION: "dth_television",
  FOOD_COURT: "food_court",
  THEATRE_HOME: "theatre_home",
  VISITORS_PARKING: "visitors_parking",
  THEATRE: "theatre",
  AIR_CONDITIONING: "air_conditioning",
  COMMON_WASHROOM: "common_washroom",
  TENNIS_COURT: "tennis_court",
  PARK: "park",
  RESERVED_PARKING: "reserved_parking",
  SERVICE_LIFT: "service_lift",
  BANK_ATM: "bank_atm",
  LIBRARY: "library",
};

export const PROJECT_AMENITIES_TAGS = [
  { id: "valet_parking", name: "Valet Parking" },
  { id: "swimming_pool", name: "Swimming Pool" },
  { id: "football_ground", name: "Football Ground" },
  { id: "flower_garden", name: "Flower Garden" },
  { id: "reading_lounge", name: "Reading Lounge" },
  { id: "reflexology_park", name: "Reflexology Park" },
  { id: "volley_ball_court", name: "Volley Ball Court" },
  { id: "card_room", name: "Card Room" },
  { id: "high_speed_elevator", name: "High-Speed Elevator" },
  { id: "sauna", name: "Sauna" },
  { id: "jacuzzi", name: "Jacuzzi" },
  { id: "steam_room", name: "Steam Room" },
  { id: "creche_day_care", name: "Creche / Day Care" },
  { id: "gazebo", name: "Gazebo" },
  { id: "wifi_connectivity", name: "WiFi Connectivity" },
  { id: "pool_table", name: "Pool Table" },
  { id: "property_staff", name: "Property Staff" },
  { id: "cafeteria", name: "Cafeteria" },
  { id: "billiards", name: "Billiards" },
  { id: "banquet_hall", name: "Banquet Hall" },
  { id: "fountain", name: "Fountain" },
  { id: "shopping_centre", name: "Shopping Centre" },
  { id: "sun_deck", name: "Sun Deck" },
  { id: "multipurpose_court", name: "Multipurpose Court" },
  { id: "earthquake_resistant", name: "Earthquake Resistant" },
  { id: "toddler_pool", name: "Toddler Pool" },
  { id: "party_lawn", name: "Party Lawn" },
  { id: "table_tennis", name: "Table Tennis" },
  { id: "basket_ball_court", name: "Basket Ball Court" },
  { id: "badminton_court", name: "Badminton Court" },
  { id: "entrance_lobby", name: "Entrance Lobby" },
  { id: "yoga_meditation_area", name: "Yoga / Meditation Area" },
  { id: "indoor_games", name: "Indoor Games" },
  { id: "cctv_camera_security", name: "CCTV Camera Security" },
  { id: "jogging_track", name: "Jogging Track" },
  { id: "rain_water_harvesting", name: "Rain Water Harvesting" },
  { id: "security_24_7", name: "Security 24/7" },
  { id: "club_house", name: "Club House" },
  { id: "power_backup_24_7", name: "Power Backup 24/7" },
  { id: "gated_community", name: "Gated Community" },
  { id: "gymnasium", name: "Gymnasium" },
  { id: "water_supply_24_7", name: "Water Supply 24/7" },
  { id: "landscape_garden", name: "Landscape Garden" },
  { id: "children_play_area", name: "Children Play Area" },
  { id: "lift", name: "Lift" },
  { id: "car_parking", name: "Car Parking" },
  { id: "carrom", name: "Carrom" },
  { id: "chess", name: "Chess" },
  { id: "open_space", name: "Open Space" },
  { id: "security_cabin", name: "Security Cabin" },
  { id: "senior_citizen_sitout", name: "Senior Citizen Sitout" },
  { id: "paved_compound", name: "Paved Compound" },
  { id: "intercom", name: "Intercom" },
  { id: "fire_fighting_systems", name: "Fire Fighting Systems" },
  { id: "squash_court", name: "Squash Court" },
  { id: "video_door_security", name: "Video Door Security" },
  { id: "ampitheatre", name: "Amphitheatre" },
  { id: "sewage_treatment_plant", name: "Sewage Treatment Plant" },
  { id: "golf_course", name: "Golf Course" },
  { id: "school", name: "School" },
  { id: "garabage_disposal", name: "Garbage Disposal" },
  { id: "ro_system", name: "RO System" },
  { id: "power_backup_lift", name: "Power Backup for Lift" },
  { id: "mini_theatre", name: "Mini Theatre" },
  { id: "concierge_service", name: "Concierge Service" },
  { id: "bar_chillout_lounge", name: "Bar / Chillout Lounge" },
  { id: "pergola", name: "Pergola" },
  { id: "piped_gas", name: "Piped Gas" },
  { id: "spa", name: "Spa" },
  { id: "skating_rink", name: "Skating Rink" },
  { id: "grocery_shop", name: "Grocery Shop" },
  { id: "wheelchair_accesible", name: "Wheelchair Accessible" },
  { id: "internal_streetlights", name: "Internal Streetlights" },
  { id: "maintenance_staff", name: "Maintenance Staff" },
  { id: "barbeque", name: "Barbeque" },
  { id: "vastu_compliant", name: "Vastu Compliant" },
  { id: "doctor_on_call", name: "Doctor on Call" },
  { id: "restaurant", name: "Restaurant" },
  { id: "cricket_pitch", name: "Cricket Pitch" },
  { id: "dth_television", name: "DTH Television" },
  { id: "food_court", name: "Food Court" },
  { id: "theatre_home", name: "Theatre Home" },
  { id: "visitors_parking", name: "Visitors Parking" },
  { id: "theatre", name: "Theatre" },
  { id: "air_conditioning", name: "Air Conditioning" },
  { id: "common_washroom", name: "Common Washroom" },
  { id: "tennis_court", name: "Tennis Court" },
  { id: "park", name: "Park" },
  { id: "reserved_parking", name: "Reserved Parking" },
  { id: "service_lift", name: "Service Lift" },
  { id: "bank_atm", name: "Bank ATM" },
  { id: "library", name: "Library" }
];

export const SLUG_ROLE_TYPES = {
  AGENT_DEALER: "agentid",
  BUILDER: "buid",
  PROJECT: "bpid",
  OWNER: "oid"
}

export const BUILDER_FORM_TYPE = {
  builder: "Builder",
  company: "Company",
  agent: "Agent"
}

export const BUILDER_FORM_TYPE_TAGS = {
  Builder: "builder",
  Company: "company",
  Agent: "agent"
}

export const PROJECT_PROPERTY_TYPE_ARRAY = [
  {id: 'residential_property', name: 'RESIDENTIAL PROPERTY'},
  {id: 'commercial_property', name: 'COMMERCIAL PROPERTY'},
  {id: 'plot', name: 'Plot'}
];

export const COMPANY_EXPERTISE_IN = [
  { id: 'property_valuation', name: 'PROPERTY VALUATION' },
  { id: 'property_search', name: 'PROPERTY SEARCH' },
  { id: 'property_management', name: 'PROPERTY MANAGEMENT' },
  { id: 'legal_and_financial_services', name: 'LEGAL AND FINANCIAL SERVICES' },
  { id: 'virtual_tours', name: 'VIRTUAL TOURS' },
  { id: 'sale', name: 'SALE' },
  { id: 'purchase', name: 'PURCHASE' },
  { id: 'rent', name: 'RENT' },
  { id: 'residential', name: 'RESIDENTIAL' },
  { id: 'commercial', name: 'COMMERCIAL' },
  { id: 'industrial', name: 'INDUSTRIAL' },
  { id: 'institutional', name: 'INSTITUTIONAL' },
  { id: 'original_booking', name: 'ORIGINAL BOOKING' },
  { id: 'pre_launch', name: 'PRE LAUNCH' },
  { id: 'others', name: 'OTHERS' }
];

export const COMPANY_EXPERTISE_IN_TAGS = {
  PROPERTY_VALUATION: "property_valuation",
  PROPERTY_SEARCH: "property_search",
  PROPERTY_MANAGEMENT: "property_management",
  LEGAL_AND_FINANCIAL_SERVICES: "legal_and_financial_services",
  VIRTUAL_TOURS: "virtual_tours",
  SALE: "sale",
  PURCHASE: "purchase",
  RENT: "rent",
  RESIDENTIAL: "residential",
  COMMERCIAL: "commercial",
  INDUSTRIAL: "industrial",
  INSTITUTIONAL: "institutional",
  ORIGINAL_BOOKING: "original_booking",
  PRE_LAUNCH: "pre_launch",
  OTHERS: "others"
}

export const ENTITY_STATUS = {
  PENDING_VERIFICATION: "pending_verification",
  ACTIVE: "active",
  INACTIVE: "inactive",
  REJECTED: "rejected"
}

export const LEAD_ACTION_TAG = {
  page_view: "Page View",
  bookmark: "Bookmark",
  call: "Call",
  requested_callback: "Callback Requested",
  requested_photo: "Photo Requested",
  view_number: "Number Viewed"
}

export const DEALS_IN = {
  RENT: "rent",
  SALE: "sale",
  RESALE: "resale",
  RESIDENTIAL: "residential",
  COMMERCIAL: "commercial",
  INDUSTRIAL: "industrial",
  VIRTUAL_TOURS: "virtual_tours",
  INSTITUTIONAL: "institutional",
  PROPERTY_SEARCH: "property_search",
  PROPERTY_VALUATION: "property_valuation",
  PROPERTY_MANAGEMENT: "property_management",
  LEGAL_AND_FINANCIAL_SERVICES: "legal_and_financial_services",
  PURCHASE: "purchase",
  ORIGINAL_BOOKING: "original_booking",
  PRE_LAUNCH: "pre_launch",
  OTHERS: "others"
}

export const DEALS_IN_TAGS = [
  { id: "rent", name: "Rent" },
  { id: "sale", name: "Sale" },
  { id: "resale", name: "Resale" },
  { id: "residential", name: "Residential" },
  { id: "commercial", name: "Commercial" },
  { id: "industrial", name: "Industrial" },
  { id: "virtual_tours", name: "Virtual Tours" },
  { id: "institutional", name: "Institutional" },
  { id: "property_search", name: "Property Search" },
  { id: "property_valuation", name: "Property Valuation" },
  { id: "property_management", name: "Property Management" },
  { id: "legal_and_financial_services", name: "Legal And Financial Services" },
  { id: "purchase", name: "Purchase" },
  { id: "original_booking", name: "Original Booking" },
  { id: "pre_launch", name: "Pre Launch" },
  { id: "others", name: "Others" }
]
 
export const LOCATION_TYPE = {
  CITY: "city",
  LOCALITY: "locality",
  ALL: "all"
}

export const REQUIREMENT_TYPE = {
  rent: "Rent",
  sell: "Buy",
};

export const REQUIREMENT_TYPE_TAGS = {
  RENT: "rent",
  BUY: "sell",
};

export const OCCUPATION_TAGS = {
  working: "Working",
  student: "Student",
  other: "Other"
};
 
export const OCCUPATION = {
  STUDENT: "student",
  WORKING: "working",
  BUSINESS: "business",
  OTHER: "other",
};

export const AVAILABLE_ROOMS = {
  SINGLE_ROOM: "single_room",
  SHARED_ROOM: "shared_room",
};

export const AVAILABLE_ROOMS_TAGS = {
  single_room: 'Single Room',
  shared_room: 'Shared Room',
};

export const SHARING_TYPE = {
  NEED_FLAT: "need_flat",
  NEED_FLATMATE: "need_flatmate",
};

export const ROOM_DETAILS = {
  ATTACHED_BATHROOM: "attached_bathroom",
  ATTACHED_BALCONY: "attached_balcony",
  AC_ROOM: "ac_Room",
};

export const ROOM_OPTIONS_OPTIONS  = [
  { value: 'attached_bathroom', label: 'Attached Bathroom' },
  { value: 'attached_balcony', label: 'Attached Balcony' },
  { value: 'ac_Room', label: 'AC Room' },
];

export  const HOMEPAGE_TYPES  = [
  { value: 'general', label: 'General' },
  { value: 'buy', label: 'Buy' },
  { value: 'rent', label: 'Rent' },
  { value: 'pg', label: 'PG' },
  { value: 'plot', label: 'Plot' },
  { value: 'commercial_buy', label: 'Commercial Buy' },
  { value: 'commercial_rent', label: 'Commercial Rent' },
];

export const HOMEPAGE_SECTIONS = {
  general: [
    { value: "projects", label: "Projects" },
    { value: "apartment_floor", label: "Apartment Floor" },
    { value: "plots", label: "Plots" },
    { value: "residential_property", label: "Residential Property" },
    { value: "pg", label: "Featured PG" }
  ],
  buy: [
    { value: "fresh_property", label: "Fresh Property" },
    { value: "hot_deals_property", label: "Hot Deal Property" },
    { value: "projects", label: "Projects" },
    { value: "popular_owner_property", label: "Popular Owner Property" },
    { value: "featured_floor", label: "Featured Floor" },
    { value: "exclusive_property", label: "Exclusive Property" }
  ],
  rent: [
    { value: "fresh_property", label: "Fresh Property" },
    { value: "office_spaces", label: "Office Spaces" },
    { value: "popular_owner_property", label: "Popular Owner Property" },
    { value: "apartment_floor", label: "Apartment Floor" },
    { value: "exclusive_property", label: "Exclusive Property" }
  ],
  pg: [
    { value: "featured_pg", label: "Featured PG" },
    { value: "pg_with_food", label: "PG With Meals" },
    { value: "popular_pg", label: "Popular PG" },
    { value: "pg_with_wifi", label: "PG With Wifi" }
  ],
  plot: [
    { value: "fresh_property", label: "Fresh Property" },
    { value: "hot_deals_property", label: "Hot Deal Property" },
    { value: "property_for_investment", label: "Property For Investment" },
    { value: "popular_owner_property", label: "Popular Owner Property" }
  ],
  commercial_buy: [
    { value: "fresh_property", label: "Fresh Property" },
    { value: "office_spaces", label: "Office Spaces" },
    { value: "featured_commercial_property", label: "Featured Commercial Property" },
    { value: "shops", label: "Shops" },
    { value: "hot_deals_property", label: "Hot Deal Property" }
  ],
  commercial_rent: [
    { value: "fresh_property", label: "Fresh Property" },
    { value: "office_spaces", label: "Office Spaces" },
    { value: "featured_commercial_property", label: "Featured Commercial Property" },
    { value: "shops", label: "Shops" },
    { value: "hot_deals_property", label: "Hot Deal Property" }
  ],
};

export const RESIDENTIAL_OPTIONS = {
  "residential_others": "Residential Others",
  "flat/apartment": "Apartment",
  "floor": "Floor",
  "independent_house": "Independent House",
  "vila/farm_house": "Independent Villa",
  "1_rk_studio": "One RK Studio"
};

export const COMMERCIAL_OPTIONS = {
  "commercial_other": "Commercial Others",
  "office": "Office",
  "retail_shop": "Retail Shop",
  "showroom": "Showroom",
  "warehouse": "Warehouse"
};


export const RESTRICTED_PROPERTY_TYPE_OPTIONS = {
  residential_others: RESIDENTIAL_OPTIONS,
  "flat/apartment": RESIDENTIAL_OPTIONS,
  floor: RESIDENTIAL_OPTIONS,
  independent_house: RESIDENTIAL_OPTIONS,
  "1_rk_studio": RESIDENTIAL_OPTIONS,
  "vila/farm_house": RESIDENTIAL_OPTIONS,
  commercial_other: COMMERCIAL_OPTIONS,
  office: COMMERCIAL_OPTIONS,
  retail_shop: COMMERCIAL_OPTIONS,
  showroom: COMMERCIAL_OPTIONS,
  warehouse: COMMERCIAL_OPTIONS
};