import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  VStack,
  HStack,
  useColorModeValue,
  SimpleGrid,
} from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import { formatDateToDDMONTHNAMEYYYY } from "utils/common";

const FilterCalendar = ({ startDate, endDate, setStartDate = () => {}, setEndDate = () => {}, onChange, onApply = () => {} }) => {
  const [dateRange, setDateRange] = useState([startDate, endDate]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPreset, setSelectedPreset] = useState(null);
  const today = new Date();

  useEffect(() => {
    // Update dateRange when a preset is selected
    if (selectedPreset) {
      const [start, end] = selectedPreset;
      setDateRange([start, end]);
    }
  }, [selectedPreset]);

  // Predefined date range functions
  const setToday = () => {
    setSelectedPreset([today, today]);
  };

  const setYesterday = () => {
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    setSelectedPreset([yesterday, yesterday]);
  };

  const setLastWeek = () => {
    const lastWeek = new Date();
    lastWeek.setDate(today.getDate() - 7);
    setSelectedPreset([lastWeek, today]);
  };

  const setLast30Days = () => {
    const last30Days = new Date();
    last30Days.setDate(today.getDate() - 30);
    setSelectedPreset([last30Days, today]);
  };

  const setLast60Days = () => {
    const last60Days = new Date();
    last60Days.setDate(today.getDate() - 60);
    setSelectedPreset([last60Days, today]);
  };

  const setLast90Days = () => {
    const last90Days = new Date();
    last90Days.setDate(today.getDate() - 90);
    setSelectedPreset([last90Days, today]);
  };

  const setLastMonth = () => {
    const lastMonth = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      today.getDate()
    );
    setSelectedPreset([lastMonth, today]);
  };

  const setLastQuarter = () => {
    const lastQuarter = new Date(
      today.getFullYear(),
      today.getMonth() - 3,
      today.getDate()
    );
    setSelectedPreset([lastQuarter, today]);
  };

  const setLast10Days = () => {
    const last10Days = new Date();
    last10Days.setDate(today.getDate() - 10);
    setSelectedPreset([last10Days, today]);
  };

  const resetDateRange = () => {
    setSelectedPreset(null);
    setDateRange([null, null]);
  };

  const onDateChange = (value) => {
    if (value[1] && value[1] > today) {
      value[1] = today;
    }
    setSelectedPreset(null); // Clear preset when manually changing the date
    setDateRange(value);
  };

  const applyFilter = () => {
      setEndDate(dateRange[1]);
      setStartDate(dateRange[0]);
      setIsOpen(false);
      onChange({
        target: {
          name: "dateRange",
          value: { startDate: dateRange[0], endDate: dateRange[1] }
        }
      });
  };

  useEffect(() => {
    if(startDate && endDate){
      onApply();
    }
  }, [startDate, endDate])

  // Custom styles for the calendar
  const calendarBg = useColorModeValue("white", "gray.700");
  const calendarBorderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Box>
      <Button
        onClick={() => setIsOpen(true)}
        leftIcon={<CalendarIcon />}
        bg="blue.500"
        color="white"
        _hover={{ bg: "blue.600" }}
      >
        {/* Select Date Range */}
      </Button>

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filter Date Range</ModalHeader>
          <ModalCloseButton />
          <ModalBody style={{ display: "flex", gap: "10px" }}>
            <SimpleGrid columns={1} spacing={2} mb={4}>
              <Button
                variant="outline"
                onClick={setToday}
                fontSize={"xs"}
                bg={
                  selectedPreset &&
                  selectedPreset[0].getTime() === today.getTime()
                    ? "blue.200"
                    : "transparent"
                }
              >
                Today
              </Button>
              <Button
                variant="outline"
                onClick={setYesterday}
                fontSize={"xs"}
                bg={
                  selectedPreset &&
                  selectedPreset[0].getTime() === today.getTime() - 86400000
                    ? "blue.200"
                    : "transparent"
                }
              >
                Yesterday
              </Button>
              <Button
                variant="outline"
                onClick={setLastWeek}
                fontSize={"xs"}
                bg={
                  selectedPreset &&
                  selectedPreset[0].getTime() === today.getTime() - 604800000
                    ? "blue.200"
                    : "transparent"
                }
              >
                Last Week
              </Button>
              <Button
                fontSize={"xs"}
                variant="outline"
                onClick={setLastMonth}
                bg={
                  selectedPreset &&
                  selectedPreset[0].getMonth() === today.getMonth() - 1
                    ? "blue.200"
                    : "transparent"
                }
              >
                Last Month
              </Button>
              <Button
                variant="outline"
                onClick={setLast30Days}
                fontSize={"xs"}
                bg={
                  selectedPreset &&
                  selectedPreset[0].getTime() === today.getTime() - 2592000000
                    ? "blue.200"
                    : "transparent"
                }
              >
                Last 30 Days
              </Button>
              <Button
                variant="outline"
                onClick={setLast60Days}
                fontSize={"xs"}
                bg={
                  selectedPreset &&
                  selectedPreset[0].getTime() === today.getTime() - 5184000000
                    ? "blue.200"
                    : "transparent"
                }
              >
                Last 60 Days
              </Button>
              <Button
                variant="outline"
                onClick={setLast90Days}
                fontSize={"xs"}
                bg={
                  selectedPreset &&
                  selectedPreset[0].getTime() === today.getTime() - 7776000000
                    ? "blue.200"
                    : "transparent"
                }
              >
                Last 90 Days
              </Button>
              <Button
                variant="outline"
                onClick={setLast10Days}
                fontSize={"xs"}
                bg={
                  selectedPreset &&
                  selectedPreset[0].getTime() === today.getTime() - 864000000
                    ? "blue.200"
                    : "transparent"
                }
              >
                Last 10 Days
              </Button>
              <Button
                variant="outline"
                onClick={resetDateRange}
                fontSize={"xs"}
                colorScheme="red"
              >
                Reset
              </Button>
            </SimpleGrid>

            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Box
                border="2px solid black"
                as={Calendar}
                selectRange
                onChange={onDateChange}
                value={dateRange}
                tileDisabled={({ date }) => date > today} // Disable dates after today
                className="react-calendar"
                bg={calendarBg}
                borderRadius="md"
                borderColor={calendarBorderColor}
                borderWidth="1px"
                boxShadow="md"
                sx={{
                  ".react-calendar__tile": {
                    padding: "5px",
                    bg: calendarBg,
                    borderRadius: "md",
                    border: "1px solid",
                    borderColor: calendarBorderColor,
                    _hover: { bg: "blue.50" },
                  },
                  ".react-calendar__tile--active": {
                    bg: "blue.500",
                    color: "white",
                    _hover: { bg: "blue.600" },
                  },
                  ".react-calendar__tile--range": {
                    bg: "blue.200",
                    color: "white",
                  },
                  ".react-calendar__tile--rangeStart, .react-calendar__tile--rangeEnd":
                    {
                      bg: "blue.500",
                      color: "white",
                    },
                  ".react-calendar__navigation button": {
                    color: "blue.500",
                    _hover: { bg: "blue.50" },
                    minWidth: "30px",
                    height: "30px",
                  },
                  ".react-calendar__navigation button:disabled": {
                    color: "gray.400",
                  },
                }}
              />
              <HStack spacing={3} mt={2} justifyContent="center">
                <Box textAlign="center">
                  <Text fontSize="sm" fontWeight="bold">
                    Start Date:
                  </Text>
                  <Text fontSize="sm">
                    {dateRange[0]
                      ? formatDateToDDMONTHNAMEYYYY(dateRange[0])
                      : "N/A"}
                  </Text>
                </Box>
                <Box textAlign="center">
                  <Text fontSize="sm" fontWeight="bold">
                    End Date:
                  </Text>
                  <Text fontSize="sm">
                    {dateRange[1]
                      ? formatDateToDDMONTHNAMEYYYY(dateRange[1])
                      : "N/A"}
                  </Text>
                </Box>
              </HStack>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={applyFilter}
              // isDisabled={!dateRange[0] || !dateRange[1]}
              bg="blue.500"
              color="white"
              _hover={{ bg: "blue.600" }}
              size="sm"
            >
              Apply Filter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default FilterCalendar;
